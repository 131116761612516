import {createAction, props} from '@ngrx/store';
import {Certificazione} from '../../model/certificazione';
import {DialogData} from '../../../shared/model/dialog.data';
import {StudentClassroom} from '../../model/student-classroom';


export const selectCertification = createAction('[UserIndexComponent NavBarSubComponent] Select Certification',
  props<{ certification: Certificazione, updateData: boolean }>()
);

export const getCertification = createAction('[UserIndexResolver] Get Certification From Server');


export const successfullyGetCertification = createAction('[UserIndexEffect] Succesfully Get Certification From Server',
  props<{ certificazioni: Certificazione[], classi?: StudentClassroom[] }>()
);

export const failToGetCertification = createAction('[UserIndexEffect] Fail To Get Certification From Server',
  props<{ dialogData: DialogData }>()
);

export const getCertificationAndSubscriptionData = createAction('[UserIndexResolver] Get Certification And Subscription Data From Server',
  props<{ idStudente: number }>(),
);


export const succesfullyGetCertificationAndSubData = createAction('[UserIndexEffect] Succesfully Get Cert And Sub Data',
  props<{ certificazioni: Certificazione[] }>()
);

export const failToGetCertAndSubData = createAction('[UserIndexEffect] Fail To Get Cert And Sub Data',
  props<{ dialogData: DialogData }>()
);
