import * as fromCertificazione from '../reducers/certification.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';


export const selectCertificazioni = createFeatureSelector<fromCertificazione.CertificationState>
(fromCertificazione.certificationFeatureKey);

export const selectAllCert = createSelector(selectCertificazioni, fromCertificazione.selectAllCertificazioni);

export const selectCurrentSelectedCertification = createSelector(selectCertificazioni, state => {
  return state.selectedCertification;
});
