<div mat-dialog-content>
  <div class="text-center" *ngIf="(data.code !== undefined && data.code !== null && data.code.startsWith('2')); else error">
    <i class="pi pi-check custom-font-size"></i>
  </div>
  <ng-template #error>
    <div class="text-center">
      <i class="pi pi-times custom-font-size" (click)="close()"></i>
    </div>
  </ng-template>
  <div class="text-center mt-3">{{data.message}}</div>
</div>
<div mat-dialog-actions>
  <p-button class="m-auto" label="CLOSE" (click)="close()"></p-button>
</div>
