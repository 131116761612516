import {url} from '../../url/http-url-resources';

export class UserUrlHttpResources {

  // Da qui riprendo le classi
  // Lo riutilizzo anche per il profilo
  public static getStudente(index: number): string {
    return url + 'rest/utente/getStudente/' + index;
  }

  public static getListaTipoTest(certificationId: number): string {
    return url + 'rest/tipoTest/listaTipoTest/' + certificationId;
  }


  public static listaSmeOMe(): string {
    return url + 'rest/test/listaTestMEorSME';
  }

  public static listaGruppoLT(classroomId: number): string {
    return url + 'rest/test/listaGruppoLT/' + classroomId;
  }


  public static getLtByGroup(): string {
    return url + 'rest/test/listaLTxGruppo';
  }

  public static getTest(testId: number, userId: number): string {
    return url + 'rest/test/getTest/' + testId + '/' + userId;
  }

  public static getTestInClasse(testId: number, userId, classroomId: number) {
    return url + 'rest/test/getTestInClasse/' + testId + '/' + userId + '/' + classroomId;
  }

  public static saveLt() {
    return url + 'rest/test/testCompletato';
  }

  public static getTestResult() {
    return url + 'rest/test/testCompletatoXreport';
  }

  public static getReportList(idAccount: number) {
    return url + 'rest/test/listaReportStudente/' + idAccount;
  }

  public static getReport(reportId: number) {
    return url + 'rest/test/getReportStudente/' + reportId;
  }

  public static modificaPassword() {
    return url + 'rest/utente/modificaPasswordAccount';
  }

  public static modificaInfoProfilo() {
    return url + 'rest/utente/modificaUtente';
  }

  public static getListaCertificazioni() {
    return url + 'rest/certificazione/listaCertificazioni';
  }

  public static listaTipoTestConStorico(idCertificazione: number, idAccount: number) {
    return url + 'rest/tipoTest/listaTipoTestConStorico/' + idCertificazione + '/' + idAccount;
  }

  public static listaGruppoLtxCertificazione(idCertificazione: number) {
    return url + 'rest/test/listaGruppoLTxCertificazione/' + idCertificazione;
  }

  public static listaLtxGruppoSenzaClasse() {
    return url + 'rest/test/listaLTxGruppoSenzaClasse';
  }

  public static listaTestMEorSMEsenzaClasse() {
    return url + 'rest/test/listaTestMEorSMEsenzaClasse';
  }

  public static setProfiloFatturazione() {
    return url + 'rest/utenteProfiloFatturazione/creaProfiloFatturazioneUtente';
  }

  public static editProfiloFatturazione() {
    return url + 'rest/utenteProfiloFatturazione/modificaProfiloFatturazioneUtente';
  }

  public static getTestXPacchettoAbbonamento() {
    return url + 'rest/test/getTestXpacchettoAbbonamento';
  }

  public static getTestXPacchettoAbbonamentoCarrello() {
    return url + 'rest/test/getTestXpacchettoAbbonamentoCarrello';
  }

  public static salvaPacchettoAbbonamento() {
    return url + 'rest/pacchettoAbbonamento/salvaPacchettoAbbonamento';
  }

  public static getListaPacchettoAbbonamento(idUtente: string) {
    return url + 'rest/pacchettoAbbonamento/getListaPacchettoAbbonamento/' + idUtente;
  }

  public static aggiornaTempoPacchettoAbbonamento(): string {
    return url + 'rest/pacchettoAbbonamento/aggiornaTempoPacchettoAbbonamento/';
  }

  public static getTestPacchettoAbbonamento(testId: number, userId: number, subId: number) {
    return url + 'rest/test/getTestPacchettoAbbonamento/' + testId + '/' + userId + '/' + subId;
  }

  public static aggiornaTestPacchettoAbbonamento(): string {
    const urlFinal = url + 'rest/pacchettoAbbonamento/aggiornaTestPacchettoAbbonamento';
    return urlFinal;
  }

  public static getNumeroLtFree(idCertificazione: number): string {
    const urlFinale = url + 'rest/abbonamento/getNumeroLtFree/' + idCertificazione;
    return urlFinale;
  }
}
