import {createReducer, on} from '@ngrx/store';
import {UserCertificationAction} from '../actions/certification.action.type';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Certificazione, compareCertificazione} from '../../model/certificazione';

export const certificationFeatureKey = 'userselectedcertification';


export interface CertificationState extends EntityState<Certificazione> {
  selectedCertification: Certificazione;
}

export const certificazioneAdapter = createEntityAdapter<Certificazione>({
  sortComparer: compareCertificazione
});

export const certificationInitialState: CertificationState = certificazioneAdapter.getInitialState({
  selectedCertification: undefined
});

export const certificationReducer = createReducer(certificationInitialState,
  on(UserCertificationAction.selectCertification, (state, action) => {
    return {...state, selectedCertification: action.certification};
  }),
  on(UserCertificationAction.succesfullyGetCertificationAndSubData, (state, action) => {
    return certificazioneAdapter.setAll(action.certificazioni, state);
  }),
  on(UserCertificationAction.successfullyGetCertification, (state, action) => {
    return certificazioneAdapter.setAll(action.certificazioni, state);
  }),
);

const {
  selectAll
} = certificazioneAdapter.getSelectors();

export const selectAllCertificazioni = selectAll;
