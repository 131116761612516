import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromAuth from './store/reducers';
import {LoginComponent} from './components/login/login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {HttpClientModule} from '@angular/common/http';
import {authReducer} from './store/reducers';
import {EffectsModule} from '@ngrx/effects';
import {LoginEffect} from './store/effects/auth.effetcts';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    HttpClientModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, authReducer),
    EffectsModule.forFeature([LoginEffect]),
  ],
})
export class AuthModule {
}
