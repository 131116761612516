import {Profile} from '../../model/profile';
import {createReducer, on} from '@ngrx/store';
import {ProfileAction} from '../actions/profile.action.type';
import {AuthActions} from '../../../auth/store/actions/action-types';

export const profileFeatureKey = 'profile';

export interface ProfileState {
  profile: Profile;
}

export const profileInitialState: ProfileState = {
  profile: undefined
};

export const profileReducer = createReducer(profileInitialState,
  on(ProfileAction.getProfileInfoSuccess, (state, action) => {
    return {...state, profile: action.profile};
  }),
  on(ProfileAction.editProfileInfoSuccess, (state, action) => {
    return {...state, profile: {...action.profileInfo}};
  }),
  on(AuthActions.logout, (state, action) => {
    return {...state, profile: undefined};
  }),
  on(ProfileAction.setProfiloFatturazioneSuccess, (state, action) => {
    return {...state, profile: {
        ...state.profile,
        profiloFatturazioneUtente: action.profiloDiFatturazione
      }};
  }),
);

