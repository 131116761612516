import {
  createReducer,
  MetaReducer, on
} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {WelcomeAction} from '../actions/welcome.action.types';
import {Certificazione} from '../../../user/model/certificazione';
import {UserCertificationAction} from '../../../user/store/actions/certification.action.type';
import {TestAdminAction} from '../../../admin/store/actions/admin.test.action.type';

export const welcomeFeatureKey = 'welcome';

export interface WelcomeState {
  showSideBar: boolean;
  currentSelectedCertification: Certificazione;
  navbarVisible: boolean;
}

export const initialState: WelcomeState = {
  showSideBar: false,
  currentSelectedCertification: undefined,
  navbarVisible: true
};

export const welcomeReducers = createReducer(initialState,
  on(WelcomeAction.openCloseSideBar, (state, action) => {
    return {...state, showSideBar: !state.showSideBar};
  }),
  on(WelcomeAction.closeSideBar, (state, action) => {
    return {...state, showSideBar: false};
  }),
  on(UserCertificationAction.selectCertification, (state, action) => {
    return {...state, currentSelectedCertification: action.certification};
  }),
  on(TestAdminAction.changeNavbarVisibility, (state, action) => {
    return {...state, navbarVisible: action.state};
  })
);


export const metaReducers: MetaReducer<WelcomeState>[] = !environment.production ? [] : [];
