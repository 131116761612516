import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../store/services/auth.service';
import {JwtDecodedObject} from '../model/jwt.decoded.object';
import * as moment from 'moment-timezone';
import {User} from '../model/user';
import {AppState} from '../../reducers';
import {Store} from '@ngrx/store';
import {AuthActions} from '../store/actions/action-types';
import {selectUser} from '../store/selectors/auth.selector';
import {map, take} from 'rxjs/operators';
import {jwtDecode} from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private store: Store<AppState>) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectUser).pipe(
      map(user => {
        if (user) {
          if (user.ruolo1 === '1' && user.ruolo2 === '1' && user.ruolo3 === '1' && user.ruolo4 === '1') {
            return true;
          } else {
            this.authService.deleteToken();
            this.router.navigateByUrl('/login');
            return false;
          }
        } else {
          const token = this.authService.getToken();
          let decoded: JwtDecodedObject;
          if (!token) {
            this.router.navigateByUrl('/login');
            return false;
          }
          try {
            decoded = jwtDecode(token);
          } catch (Exception) {
            this.authService.deleteToken();
            this.router.navigateByUrl('/login');
            return false;
          }
          if (decoded.ruolo1 !== '1' && decoded.ruolo2 !== '1' && decoded.ruolo3 !== '1' && decoded.ruolo4 !== '1') {
            this.authService.deleteToken();
            this.router.navigateByUrl('/login');
            return false;
          }
          const expirationDate = new Date(decoded.exp * 1000);
          if (moment.tz(new Date(), 'Europe/London').isSameOrAfter(moment.tz(expirationDate, 'Europe/London'))) {
            this.authService.deleteToken();
            this.router.navigateByUrl('/login');
            return false;
          }
          const userFromToken: User = {
            id: decoded.id,
            ruolo1: decoded.ruolo1,
            certificazione1: decoded.certificazione1,
            ruolo2: decoded.ruolo2,
            certificazione2: decoded.certificazione2,
            ruolo3: decoded.ruolo3,
            certificazione3: decoded.certificazione3,
            ruolo4: decoded.ruolo4,
            certificazione4: decoded.certificazione4,
            exp: decoded.exp,
            username: decoded.sub,
            ip: decoded.ip,
            userAgent: decoded.userAgent
          };
          this.store.dispatch(AuthActions.loginWithToken({user: userFromToken}));
          return  true;
        }
      }),
      take(1)
    );
  }
}
