import {createAction, props} from '@ngrx/store';
import {DialogData} from '../../model/dialog.data';
import {ExamData} from '../../../user/model/examdata';

export const openDialog = createAction('[Shared Module] Open Material Dialog',
  props<{ dialogData: DialogData }>());

export const closeDialog = createAction('[Shared Module] Close Material Dialog');

export const openConfirmDialog = createAction('[Shared Module] Open Confirm Material Dialog',
  props<{ dialogData: DialogData}>());

export const closeConfirmDialog = createAction('[Shared Module] Close Confirm Material Dialog',
  props<{ confirm: boolean, cause?: string, destination?: string, examData?: ExamData, currentUser?: string, classroomId?: number}>());


