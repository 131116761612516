import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-admin-loggedin-pc',
  templateUrl: './sidebar-admin-loggedin-pc.component.html',
  styleUrls: ['./sidebar-admin-loggedin-pc.component.css']
})
export class SidebarAdminLoggedinPcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
