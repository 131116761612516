import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {ButtonModule} from 'primeng/button';
import {StoreModule} from '@ngrx/store';
import * as fromLoadingIndicator from './store/reducers';
import {sharedComponentReducer} from './store/reducers';
import {DialogComponent} from './components/dialog/dialog.component';
import {EffectsModule} from '@ngrx/effects';
import {DialogEffect} from './store/effects/dialog.effects';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
// DO NOT DELETE MATDIALOG AND MATDIALOGREF BECAUSE THEY ARE MANDATORY FOR INJECTION

@NgModule({
  declarations: [NotFoundComponent, DialogComponent, ConfirmDialogComponent],
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    StoreModule.forFeature(fromLoadingIndicator.loadingIndicatorFeatureKey, sharedComponentReducer),
    EffectsModule.forFeature([DialogEffect])
  ],
  exports: [NotFoundComponent, DialogComponent],
})
export class SharedModule {
}
