import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {Router} from '@angular/router';
import {AuthActions} from '../../store/actions/action-types';
import {UserCredential} from '../../model/user.credential';
import {Observable} from 'rxjs';
import {selectErrorOnLogin, selectShowPassword} from '../../store/selectors/auth.selector';
import {LoginComponentActions} from '../../store/actions/login.component.actions.types';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public showPassword$: Observable<boolean>;
  public error$: Observable<boolean>;

  public loginForm = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(private store: Store<AppState>, private router: Router) {
  }

  ngOnInit(): void {
    this.showPassword$ = this.store.pipe(select(selectShowPassword));
    this.error$ = this.store.pipe(select(selectErrorOnLogin));
  }

  public showPassword() {
    this.store.dispatch(LoginComponentActions.showPassord());
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }


  public login() {
    const userCredential: UserCredential = {username: this.loginForm.value.username, password: this.loginForm.value.password};
    this.store.dispatch(AuthActions.login({userCredential}));
  }

  public navigateToForgotPassword() {
    this.router.navigateByUrl('/forgotpassword');
  }

  public navigateToRegistration(){
    this.router.navigateByUrl('/signup');
  }

}
