import {createAction, props} from '@ngrx/store';
import {StudentTest} from '../../model/student-test';
import {DialogData} from '../../../shared/model/dialog.data';

export const getLtTest = createAction('[LearningExamList Component] Get Test From Server',
  props<{ testId: number, userId: number, testIsDoneInClass: boolean }>()
);

export const getLtPremium = createAction('[Subscriptions Component] Get Test From Server',
  props<{ testId: number, userId: number, subId: number}>()
);

export const succesfullyGetLt = createAction('[LearningTestEffect] Succesfully Get Lt From Server',
  props<{ lt: StudentTest, testIsDoneInClass: boolean }>()
);

export const failToGetLt = createAction('[LearningTestEffect] Fail To Get Lt From Server',
  props<{ dialogData: DialogData }>()
);

export const finishLtAndSendData = createAction('[LearningTestComponent] Save Lt Status',
  props<{ data: { idAccount: number, idTest: number, idClasse?: number, idPacchetto?: number }, fromClass: boolean, ruolo: number, updateExamListData: boolean }>()
);

export const failToSaveLtOutcome = createAction('[LearningTestEffect] Fail To Save Lt Outcome',
  props<{ dialogData: DialogData }>()
);

export const succesfullySaveLtOutcome = createAction('[LearningTestEffect] Succesfully Save Lt Outcome',
  props<{ dialogData: DialogData, ltId: number, fromClass: boolean, ruolo: number, updateExamListData: boolean}>()
);

export const endLtBeforeTestEnded = createAction('[LearningTestComponent] Reset Lt Data If Test Is Ended Before FInish');
