import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {Router} from '@angular/router';
import {AuthActions} from '../../../../auth/store/actions/action-types';
import {WelcomeAction} from '../../../store/actions/welcome.action.types';
import {MenuItem} from 'primeng/api';
import {UserCertificationAction} from '../../../../user/store/actions/certification.action.type';
import {
  selectAllCert,
  selectCurrentSelectedCertification
} from '../../../../user/store/selectors/certificazione.selector';
import {take, tap} from 'rxjs/operators';
import {Certificazione} from '../../../../user/model/certificazione';
import {Subscription} from 'rxjs';
import {selectCurrentSelectedCertificationNavBar} from '../../../store/selectors/welcome.selectors';
import {CartService} from '../../../../user/store/services/cart.service';
import {User} from "../../../../auth/model/user";
import {selectUser} from "../../../../auth/store/selectors/auth.selector";
import {openConfirmDialog} from "../../../../shared/store/actions/material.dialog.action";
import {DialogData} from "../../../../shared/model/dialog.data";


@Component({
  selector: 'app-user-loggedin-pc',
  templateUrl: './user-loggedin-pc.component.html',
  styleUrls: ['./user-loggedin-pc.component.css']
})
export class UserLoggedinPcComponent implements OnInit, OnDestroy {


  private subscription: Subscription;
  private certificationSubscription: Subscription;
  items: MenuItem[];
  selectedCertification: Certificazione;
  itemInCart = 0;
  user: User;
  cartSub: Subscription;
  getCartSub: Subscription;
  userSub: Subscription;

  constructor(private store: Store<AppState>, public router: Router, private cartService: CartService) {
  }

  ngOnInit(): void {
    /*Le Label non sono dinamiche nella navbar */
    this.items = [
      {label: 'PMP'},
      {label: 'PMI-ACP'},
      {label: 'CAPM'},
      {label: 'NEW CAPM'}
    ];
    this.certificationSubscription = this.store.pipe(
      select(selectCurrentSelectedCertificationNavBar),
      tap(certification => {
        this.selectedCertification = certification;
      })
    ).subscribe();
    this.getCartSub = this.cartService.getCart().subscribe(cart => {
      this.itemInCart = cart.length;
    });
    this.cartSub = this.cartService.cartUpdated.subscribe(
      cartElem => {
        this.itemInCart = cartElem;
      }
    );
    this.userSub = this.store.select(selectUser).subscribe(
      user => this.user = user
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.certificationSubscription) {
      this.certificationSubscription.unsubscribe();
    }
    if (this.getCartSub) {
      this.getCartSub.unsubscribe();
    }
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  navigate(destination: string, event?: Event) {
    if(destination == 'digital-media') {
      window.location.href = 'https://lp.elis.org/pm-test-elis/digital-media/';
      return;
    }
    if(this.router.url.includes('/test/question') || this.router.url.includes('test/examreview') || this.router.url.includes('learningtest')){
      const dialogData: DialogData = {
        message: 'ARE YOU SURE YOU WANT TO LEAVE THE TEST SESSION?',
        code: undefined,
        cause: 'imperative',
        destination: '/user/' + destination
      };
      this.store.dispatch(openConfirmDialog({dialogData}));
    } else {
      const url = '/user/' + destination;
      this.router.navigateByUrl(url);
    }
  }

  navigateToPublicPages() {
    window.location.href = "https://lp.elis.org/pm-test-elis/";
  }

  openCloseSideBar() {
    this.store.dispatch(WelcomeAction.openCloseSideBar());
  }

  clicked(event: MouseEvent) {
    const selected = (event.target as HTMLElement).innerText;
    this.subscription = this.store.pipe(
      select(selectAllCert),
      tap(certification => {
        const selectedCert: Certificazione = certification.filter(elem => elem.tipo === selected)[0];
        let updateData = true;
        if (this.selectedCertification && selectedCert && this.selectedCertification.id === selectedCert.id) {
          updateData = false;
        }
        if (selectedCert.id === +this.user.certificazione1) {
          this.store.dispatch(AuthActions.setRuoloStudent({ruolo: this.user.ruolo1}));
        } else if (selectedCert.id === +this.user.certificazione2) {
          this.store.dispatch(AuthActions.setRuoloStudent({ruolo: this.user.ruolo2}));
        } else if (selectedCert.id === +this.user.certificazione3) {
          this.store.dispatch(AuthActions.setRuoloStudent({ruolo: this.user.ruolo3}));
        } else if (selectedCert.id === +this.user.certificazione4) {
          this.store.dispatch(AuthActions.setRuoloStudent({ruolo: this.user.ruolo4}));
        }
        this.store.dispatch(UserCertificationAction.selectCertification({certification: selectedCert, updateData}));
        this.router.navigateByUrl('user/dashboard');
      }),
      take(1)
    ).subscribe();
  }

}
