import {Component, Inject, OnInit} from '@angular/core';
import {DialogData} from '../../model/dialog.data';
import {Store} from '@ngrx/store';
import {DialogAction} from '../../store/actions/material.dialog.action.types';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    public store: Store,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
  }

  close(confirm: boolean) {
    const dialogData = {
      confirm,
      cause: this.data.cause,
      destination: this.data.destination,
      examData: this.data.examData,
      currentUser: this.data.currentUser,
      classroomId: this.data.classroomId
    };
    this.store.dispatch(DialogAction.closeConfirmDialog({...dialogData}));
  }
}
