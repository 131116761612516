import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-user-loggedout-pc',
  templateUrl: './sidebar-user-loggedout-pc.component.html',
  styleUrls: ['./sidebar-user-loggedout-pc.component.css']
})
export class SidebarUserLoggedoutPcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
