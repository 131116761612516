import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {AppState} from './reducers';
import {select, Store} from '@ngrx/store';
import {interval, Observable, Subscription} from 'rxjs';
import {showLoadingIndicator} from './shared/store/selectors/loading-indicator.selectors';
import {ActivationEnd, Router} from '@angular/router';
import {delay, filter, map, tap} from 'rxjs/operators';
import {AuthService} from './auth/store/services/auth.service';
import {JwtDecodedObject} from './auth/model/jwt.decoded.object';
import * as moment from 'moment-timezone';
import {AuthActions} from './auth/store/actions/action-types';
import {TimezoneAction} from './shared/store/actions/timezone.action.type';
import {Actions, ofType} from '@ngrx/effects';
import {jwtDecode} from "jwt-decode";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'PMTest';
  showNavBar$: Observable<boolean>;
  loadingData: Observable<boolean>;
  loginTokenChecker: Subscription;
  nazioniSubscription: Subscription;

  constructor(private primengConfig: PrimeNGConfig, private store: Store<AppState>, private router: Router,
              private authService: AuthService, private actions$: Actions) {
  }

  onActivate(event: Event): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    AuthService.codeVerifica = localStorage.getItem('codeVerifica');
    this.store.dispatch(TimezoneAction.getTimezone());
    this.primengConfig.ripple = true;
    this.nazioniSubscription = this.actions$.pipe(
      ofType(TimezoneAction.failToGetTimezone),
      delay(4000),
      tap(() => {
        this.store.dispatch(TimezoneAction.getTimezone());
      })
    ).subscribe();
    this.loadingData = this.store.pipe(select(showLoadingIndicator));
    this.showNavBar$ = this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map((event: ActivationEnd) => event.snapshot.routeConfig.path),
      map(value => value !== '**')
    );
    this.loginTokenChecker = interval(10000).pipe(
      tap(() => {
        if (this.router.url && (this.router.url.startsWith('/admin/') || this.router.url.startsWith('/user/'))) {
          const token = this.authService.getToken();
          let decoded: JwtDecodedObject;
          if (!token) {
            this.store.dispatch(AuthActions.logout());
            this.router.navigateByUrl('/login');
            return;
          }
          try {
            decoded = jwtDecode(token);
          } catch (Exception) {
            this.authService.deleteToken();
            this.store.dispatch(AuthActions.logout());
            this.router.navigateByUrl('/login');
          }
          const expirationDate = new Date(decoded.exp * 1000);
          if (moment.tz(new Date(), 'Europe/London').isSameOrAfter(moment.tz(expirationDate, 'Europe/London'))) {
            this.authService.deleteToken();
            this.store.dispatch(AuthActions.logout());
            this.router.navigateByUrl('/login');
            return;
          }
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.loginTokenChecker) {
      this.loginTokenChecker.unsubscribe();
    }
    if (this.nazioniSubscription) {
      this.nazioniSubscription.unsubscribe();
    }
  }
}
