import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {DialogAction} from '../actions/material.dialog.action.types';
import {map, tap} from 'rxjs/operators';
import {DialogComponent} from '../../components/dialog/dialog.component';
import {ConfirmDialogComponent} from '../../components/confirm-dialog/confirm-dialog.component';
import {ClockService} from '../../../user/store/services/clock.service';
import {StudentTestAction} from '../../../user/store/actions/studenttest.action.type';
import {AuthService} from '../../../auth/store/services/auth.service';
import {SubscriptionService} from '../../../user/store/services/subscription.service';
import {Router} from "@angular/router";
import {StudentLearninTestAction} from "../../../user/store/actions/learning-test.action.type";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class DialogEffect {

  constructor(
    private actions: Actions,
    private dialog: MatDialog,
    private clock: ClockService,
    private subscriptionService: SubscriptionService,
    private router: Router
  ) {}

  openDialog = createEffect(() => this.actions
    .pipe(
      ofType(DialogAction.openDialog),
      tap((action) => {
        let dialogData;
        if (
          !AuthService.codeVerifica &&
          !action.dialogData.message.includes('ticket')
          && !action.dialogData.message.includes('The token for requesting a new password')
          && !action.dialogData.message.includes('A new password has been generated')
          && !action.dialogData.message.includes('Congratulations, check the email just sent')
        ) {
          dialogData = {
            ...action.dialogData,
            message: 'You have done the login on another platform'
          };
        }else {
          dialogData = action.dialogData;
        }
        this.dialog.open(DialogComponent, {data: dialogData});
      })
    ), {dispatch: false}
  );

  openConfirmDialog = createEffect(() => this.actions
    .pipe(
      ofType(DialogAction.openConfirmDialog),
      tap((action) => {
        if (!this.subscriptionService.subExpired){
          this.dialog.open(ConfirmDialogComponent, {data: action.dialogData});
        }
      })
    ), {dispatch: false}
  );

  closeConfirmDialog = createEffect(() => this.actions
    .pipe(
      ofType(DialogAction.closeConfirmDialog),
      tap((action) => {
        if(action.cause == 'imperative' && action.confirm) {
          this.clock.restoreData();
          this.router.navigateByUrl(action.destination);
        }
      }),
      map((action) => {
        if (action.cause === 'start-test' && action.confirm) {
          this.clock.restoreData();
          return StudentTestAction.getTest(
            {
              userId: +action.currentUser,
              testId: action.examData.id,
              examIsDoneInClass: action.classroomId ? true : false,
              classroomId: action.classroomId
            });
        }else if(action.cause === 'start-test-premium' && action.confirm){
          this.clock.restoreData();
          this.router.navigateByUrl('user/test/question', {state: {data: true}});
          this.subscriptionService.startExam(null, action.examData.subId);
          return StudentTestAction.getTestPremium({
            userId: +action.currentUser,
            testId: action.examData.id,
            subId: action.examData.subId}
          );
        } else if(action.cause === 'start-lt-premium' && action.confirm){
          this.clock.restoreData();
          this.router.navigateByUrl('user/learningtest');
          this.subscriptionService.startExam(null, action.examData.subId);
          return StudentLearninTestAction.getLtPremium({
            testId: action.examData.id,
            userId: +action.currentUser,
            subId: action.examData.subId
          });
        } else {
          return StudentTestAction.closedConfirmDialog()
        }
        // else {
        //   return StudentTestAction.restoreTestData();
        // }
      }),
    ));
}
