<div class="border-bottom">
  <div class="flexbox-wrapper">
    <div class="flex-item mr-auto right pt-2 pb-2 pl-4 pr-3">
      <a href="https://lp.elis.org/pm-test-elis"><img class="pclogo" src="assets/logo.png" alt="logo PMTest"></a>
      <div (click)="navigate('signup')" class="font-italic px-2 text-light position-absolute free-trial">FREE TRIAL!</div>
    </div>
    <div class="flex-item d-flex pt-3 pb-2 pl-3 pr-3">
      <!--<button pButton
              pRipple type="button" label="HOME" (click)="navigate('')"
              class="p-button-secondary p-button-text btn-menu">
      </button>-->
      <a href="https://lp.elis.org/pm-test-elis/" class="btn-menu">HOME</a>
    </div>
    <div class="flex-item d-flex pt-3 pb-2 pl-3 pr-3">
      <!--<button pButton
              pRipple type="button" label="CERTIFICATIONS" (click)="navigate('', 'certifications')"
              class="p-button-secondary p-button-text btn-menu">
      </button>-->
      <a href="https://lp.elis.org/pm-test-elis/#lp-pom-block-645" class="btn-menu">CERTIFICATIONS</a>
    </div>
    <div class="flex-item d-flex pt-3 pb-2 pl-3 pr-3">
      <!--<button pButton
              pRipple type="button" label="PLANS AND PRICES" (click)="navigate('', 'plans')"
              class="p-button-secondary p-button-text btn-menu">
      </button>-->
      <a href="https://lp.elis.org/pm-test-elis/#lp-pom-block-1233" class="btn-menu">PLANS AND PRICES</a>
    </div>
    <div class="flex-item d-flex pt-3 pb-2 pl-3 pr-3">
      <!--<button pButton
              pRipple type="button" label="MAIN FEATURES" (click)="navigate('', 'features')"
              class="p-button-secondary p-button-text btn-menu">
      </button>-->
      <a href="https://lp.elis.org/pm-test-elis/#lp-pom-block-376" class="btn-menu">MAIN FEATURES</a>
    </div>
    <div class="flex-item d-flex pt-3 pb-2 pl-3 pr-3">
      <!--<button pButton
              pRipple type="button" label="FAQ" (click)="navigate('help')"
              class="p-button-secondary p-button-text btn-menu">
      </button>-->
      <a target="_blank" href="https://lp.elis.org/pm-test-elis/faq/" class="btn-menu">FAQ</a>
    </div>
    <div class="flex-item d-flex pt-3 pb-2 pl-3 pr-3">
      <!--<button pButton
              pRipple type="button" label="DIGITAL MEDIA" (click)="navigate('digital-media')"
              class="p-button-secondary p-button-text btn-menu">
      </button>-->
      <a target="_blank" href="https://lp.elis.org/pm-test-elis/digital-media/" class="btn-menu">DIGITAL MEDIA</a>
    </div>
    <div class="flex-item d-flex pt-3 pb-2 pl-3 pr-3">
      <!--<button pButton
              pRipple type="button" label="CONTACT US" (click)="navigate('help', 'contact')"
              class="p-button-secondary p-button-text btn-menu">
      </button>-->
      <a target="_blank" href="https://f6218ff2.sibforms.com/serve/MUIEAADhrq8YDzJJVPEEhqogaT0uVUBVkVgv72Dk96fSis-1OOKHtZ6cy5Gphwd7R7u2LjHgJOuRiH25glUcMO2jy5VjjNh-L_K-hLzHj48oZITXW9cw2yK6jkIpxF9jgZQe3__lCsmH-0JndCLxE1UQPId3xb87ZPv0wujQz9OUaQPlHymONgfVCSvN2E1GnrzRJBATbvmL96Zr" class="btn-menu">CONTACT US</a>
    </div>
    <!--
    <div class="flex-item pt-3 pb-2 pl-3 pr-3">

      <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton
              pRipple type="button" icon="pi pi-home" label="HOME"
              class="p-button-secondary p-button-text" (click)="navigate('/')">

      </button>
    </div>
    <div class="flex-item pt-3 pb-2 pl-3 pr-3">
      <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton
              pRipple type="button" icon="pi pi-info-circle" label="CERTIFICAZIONI PMI"
              class="p-button-secondary p-button-text">

      </button>
    </div>

    <div class="flex-item pt-3 pb-2 pl-3 pr-3">
      <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton
              pRipple type="button" label="PREZZI" icon="pi pi-dollar"
              class="p-button-secondary p-button-text"></button>
    </div>
    -->
    <div class="flex-item ml-auto mr-4 pt-3 pb-2 pl-3 pr-3">
      <p-menu #menu [popup]="true" [model]="items"></p-menu>
      <button pButton
              pRipple type="button" icon="pi pi-sign-in" label="LOGIN" (click)="menu.toggle($event)"
              class="p-button-secondary p-button-text btn-menu">

      </button>
    </div>
    <!--
    <div class="flex-item pt-3 pb-2 pl-3 pr-3">
      <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="openCloseSideBar()" pButton
              pRipple type="button" label="MENU" icon="pi pi-bars"
              class="p-button-secondary p-button-text"></button>
    </div>
    -->
  </div>
</div>
