import { Component, OnInit } from '@angular/core';
import {WelcomeAction} from '../../../store/actions/welcome.action.types';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';

@Component({
  selector: 'app-admin-loggedin-telephone',
  templateUrl: './admin-loggedin-telephone.component.html',
  styleUrls: ['./admin-loggedin-telephone.component.css']
})
export class AdminLoggedinTelephoneComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  openCloseSideBar() {
    this.store.dispatch(WelcomeAction.openCloseSideBar());
  }

}
