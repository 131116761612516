<div class="position-absolute custom-position">
  <div class="row no-gutters justify-content-center mt-2 mt-md-0">
    <div class="col-10 col-md-6">
      <div class="card">
        <div class="p-3">
          <h4 class="mb-0">Password Assistance</h4>
          <p style="font-size: 16px;" class="mb-0">Enter the email address associated with your account.</p>
          <div class="row no-gutters justify-content-center mt-4">
            <div class="col-11">
              <form #resetPassowrdForm="ngForm" (ngSubmit)="resetPassword()">
                <div class="form-group">
                  <label for="email"><b>Email address</b></label>
                  <input #email1="ngModel" [(ngModel)]="email" email  required type="email" class="form-control" id="email" name="email" placeholder="mario.rossi@gmail.com">
                  <p *ngIf="email1.touched && !email1.valid" class="text-danger pl-2" style="font-size: 12px">
                    Please enter a valid email
                  </p>
                </div>
                <div class="text-center mt-4">
                  <button [disabled]="!email1.valid" sable pButton pRipple type="submit" label="Continue" class="p-button-rounded p-button-sm"></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 p-1">
        <h6>
          Has your email changed?
        </h6>
        <p style="font-size: 14px">
          If you no longer use the email address associated with your account, you may contact us for help restoring access to your account.
        </p>
      </div>
    </div>
  </div>
</div>
