import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Timezone} from '../../model/timezone';
import {url} from '../../../url/http-url-resources';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  private url = url + 'rest/zona/listaZone';

  constructor(private http: HttpClient) {
  }

  public getNazioni(): Observable<Timezone[]> {
    return this.http.get<Timezone[]>(this.url);
  }

}
