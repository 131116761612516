import {createAction, props} from '@ngrx/store';
import {Timezone} from '../../model/timezone';

export const getTimezone = createAction('[AppComponent] Get Nation From Server');

export const successfullyGetTimezone = createAction('[TimezoneEffect] Successfully Get Timezone',
  props<{timeZone: Timezone[]}>()
);

export const failToGetTimezone = createAction('[TimezoneEffect] Fail To Get Timezone');
