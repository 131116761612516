import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {WelcomeAction} from '../../../store/actions/welcome.action.types';

@Component({
  selector: 'app-user-loggedin-telephone',
  templateUrl: './user-loggedin-telephone.component.html',
  styleUrls: ['./user-loggedin-telephone.component.css']
})
export class UserLoggedinTelephoneComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  openCloseSideBar() {
    this.store.dispatch(WelcomeAction.openCloseSideBar());
  }
}
