import {createFeatureSelector, createSelector} from '@ngrx/store';
import {authFeatureKey, AuthState} from '../reducers';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectShowPassword = createSelector(selectAuthState, auth => auth.showPassword);

export const selectErrorOnLogin = createSelector(selectAuthState, auth => auth.error);

export const selectUser = createSelector(selectAuthState, auth => auth?.user);

export const isLoggedIn = createSelector(selectAuthState, auth => !!auth.user);

export const isAdmin = createSelector(selectUser, (user) => user.ruolo === '1');
