export class HttpUrlResources {

  public static loginUrl(): string {
    return url + 'login';
  }

  public static resetPassword(): string {
    return url + 'rest/utente/generaPasswordUtente';
  }

  public static logoutUrl(): string {
    return url + 'rest/utente/logoutUtente';
  }

  public static sendResetPasswordEmail(): string {
    return url + 'rest/utente/generaTokenPasswordUtente';
  }

  public static signUp(){
    return url + 'rest/utente/creaUtente';
  }
}

export const url = 'https://devapi.pm-test.com/pmtest/'; // dev server
// export const url = 'https://testapi.pm-test.com/pmtest/'; //test server
//export const url = 'https://api.pm-test.com/pmtest/'; // prod server
