import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TimezoneAction} from '../actions/timezone.action.type';
import {catchError, concatMap, map} from 'rxjs/operators';
import {TimezoneService} from '../service/timezone.service';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';

@Injectable()
export class TimezoneEffect {

  constructor(private actions$: Actions, private nazioniService: TimezoneService) {
  }

  getNazioni$ = createEffect(() => this.actions$.pipe(
    ofType(TimezoneAction.getTimezone),
    concatMap(action => this.nazioniService.getNazioni().pipe(
      map(timeZone => {
        return TimezoneAction.successfullyGetTimezone({timeZone});
      }),
      catchError((error: HttpErrorResponse) => {
        return of(TimezoneAction.failToGetTimezone());
      })
      )
    )
    )
  );
}
