import {createFeatureSelector, createSelector} from '@ngrx/store';
import {profileFeatureKey, ProfileState} from '../reducers/profile.reducer';

export const selectProfileState = createFeatureSelector<ProfileState>(profileFeatureKey);


export const getProfileObj = createSelector(selectProfileState, state => {
  if (state && state.profile) {
    return state.profile;
  } else {
    return {nome: '', cognome: '', azienda: '', email: '', telefono: '', zona: ''};
  }
});
