export function getSuccessMessage(lang: string, from: string): string {
  switch (lang) {
    default:
      switch (from) {
        case 'signup':
          return 'Congratulations, check the email just sent';
        case 'closeClassroom':
          return 'Succesfully Close Class';
        case 'deleteImage':
          return 'Image has been deleted';
        case 'saveImage':
          return 'Image succesfully upload';
        case 'editQuestion':
          return 'Question Updated';
        case 'createClassroom':
          return 'Classroom created';
        case 'editProfile':
          return 'Succesfully edit profile info';
        case 'editPassword':
          return 'Succesfully edit password';
        case 'setProfiloFatturazione':
          return 'Your billing profile has been successfully saved';
        case 'salvaTicket':
          return 'Successfully send ticket!';
        case 'modificaTicket':
          return 'Ticket edited successfully';
        case 'modificaDateStudent':
          return 'Period changed for the student in classroom created from template'
        default: return null;
      }
  }
}
