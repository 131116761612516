import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {Router} from '@angular/router';
import {AuthActions} from '../../../../auth/store/actions/action-types';
import {WelcomeAction} from '../../../store/actions/welcome.action.types';
import {MenuItem} from 'primeng/api';
import {TicketService} from "../../../../shared/store/service/ticket.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-admin-loggedin-pc',
  templateUrl: './admin-loggedin-pc.component.html',
  styleUrls: ['./admin-loggedin-pc.component.css']
})
export class AdminLoggedinPcComponent implements OnInit, OnDestroy {

  testItems: MenuItem[];
  studentItems: MenuItem[];
  classRoomItems: MenuItem[];
  questionItems: MenuItem[];
  reportItems: MenuItem[];
  ticketBadge: number;
  ticketSub: Subscription;
  ticketClosedSub: Subscription;

  constructor(private store: Store<AppState>, private router: Router, private ticketService: TicketService) {
  }

  ngOnInit(): void {
    this.testItems = [
      {label: 'CREATE', routerLink: ['/admin/createtest']},
      {label: 'MANAGE', routerLink: ['/admin/testlist']},
    ];

    this.studentItems = [
      {label: 'CREATE', routerLink: ['/admin/createstudent']},
      {label: 'MANAGE', routerLink: ['/admin/studentlist']},
    ];

    this.classRoomItems = [
      {label: 'TEMPLATES', routerLink: ['/admin/templates']},
      {label: 'CREATE', routerLink: ['/admin/createclassroom']},
      {label: 'MANAGE', routerLink: ['/admin/classroomlist']},
    ];

    this.questionItems = [
      {label: 'CREATE', routerLink: ['/admin/createquestion']},
      {label: 'MANAGE', routerLink: ['/admin/questionslist']},
    ];

    this.reportItems = [
      {label: 'TEST SVOLTI', routerLink: ['/admin/export-report']},
      {label: 'PERFORMANCE IN TEST', routerLink: ['admin/export-performance-report']},
      {label: 'USERS REPORT', routerLink: ['admin/export-utenti-report']}
    ]

    this.ticketSub = this.ticketService.getTicket().subscribe((ticket) => {
      ticket = ticket.filter(t => t.stato != 2);
      this.ticketBadge = ticket.length;
    })

    this.ticketClosedSub = this.ticketService.ticketClosed.subscribe(() => this.ticketBadge--);
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  navigate(destination: string) {
    this.router.navigateByUrl(destination);
  }

  openCloseSideBar() {
    this.store.dispatch(WelcomeAction.openCloseSideBar());
  }

  ngOnDestroy() {
    if(this.ticketSub){
      this.ticketSub.unsubscribe();
    }

    if(this.ticketClosedSub){
      this.ticketClosedSub.unsubscribe();
    }
  }

}
