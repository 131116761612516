import {createAction, props} from '@ngrx/store';
import {User} from '../../model/user';
import {UserCredential} from '../../model/user.credential';
import {DialogData} from '../../../shared/model/dialog.data';

export const login = createAction(
  '[Login Page] User Login',
  props<{ userCredential: UserCredential }>()
);

export const loginSuccess = createAction('[Login Effect] User Succesfully Login', props<{ user: User }>());
export const tokenUpdated = createAction('[SubscriptionService] Token reloaded', props<{ user: User }>());

export const loginFail = createAction('[Login Effect] User Login Fail');

export const logout = createAction(
  '[Top Menu,Side Menu] Logout'
);

export const loginWithToken = createAction(
  '[Admin Guard,Already Login Guard, User Guard] Login With A Valid Token In LocalStorage',
  props<{ user: User }>());

export const logOutSuccess = createAction('[AuthEffect] Server Correctly Invalidate The Token');

export const logOutFail = createAction('[[AuthEffect] Server Does Not Invalidate The Token]',
  props<{dialogData: DialogData}>());

export const loginFailForInternetConnection = createAction('[Login Component] Login Fail For Internet Connection');

export const setRuoloStudent = createAction('[DashbordComponent] Set User ruolo', props<{ruolo: string}>());
