import {createReducer, MetaReducer, on} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {User} from '../../model/user';
import {AuthActions} from '../actions/action-types';
import {LoginComponentActions} from '../actions/login.component.actions.types';


export const authFeatureKey = 'auth';

export interface AuthState {
  user: User;
  showPassword: boolean;
  error: boolean;
}


export const initialAuthState: AuthState = {
  user: undefined,
  showPassword: false,
  error: false
};

export const authReducer = createReducer(initialAuthState,
  on(AuthActions.login, (state, action) => {
    return {...state, error: false};
  }),

  on(LoginComponentActions.showPassord, (state, action) => {
    return {...state, showPassword: !state.showPassword};
  }),

  on(AuthActions.loginSuccess, (state, action) => {
    return {...state, showPassword: false, error: false, user: action.user};
  }),

  on(AuthActions.tokenUpdated, (state, action) => {
    return {...state, showPassword: false, error: false, user: action.user}
  }),

  on(AuthActions.loginFail, (state, action) => {
    return {...state, user: undefined, error: true, showPassword: false};
  }),

  on(AuthActions.loginWithToken, (state, action) => {
    return {...state, user: action.user, error: false, showPassword: false};
  }),

  on(AuthActions.logout, (state, action) => {
    return {...state, user: undefined, error: false, showPassword: false};
  }),
  on(AuthActions.setRuoloStudent, (state, action) => {
    const newUser = {...state.user, ruolo: action.ruolo};
    return {...state, user: {...state.user, ...newUser}};
  })
);

export const metaReducers: MetaReducer<AuthState>[] = !environment.production ? [] : [];
