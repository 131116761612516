import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DialogData} from '../../../shared/model/dialog.data';
import {HttpUrlResources} from '../../../url/http-url-resources';
import {Signup} from '../../model/signup';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(private http: HttpClient) {
  }

  signUp(data: Signup): Observable<DialogData> {
    return this.http.post<DialogData>(HttpUrlResources.signUp(), data);
  }
}
