import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexPageComponent} from './components/index-page/index-page.component';
import {NavBarComponent} from './components/nav-bar/nav-bar.component';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {SidebarModule} from 'primeng/sidebar';
import {StoreModule} from '@ngrx/store';
import * as fromWelcome from './store/reducers';
import {welcomeReducers} from './store/reducers';
import {MenuModule} from 'primeng/menu';
import {UserLoggedinPcComponent} from './components/nav-bar-profile-component/user-loggedin-pc/user-loggedin-pc.component';
import {AdminLoggedinPcComponent} from './components/nav-bar-profile-component/admin-loggedin-pc/admin-loggedin-pc.component';
import {UserLoggedoutPcComponent} from './components/nav-bar-profile-component/user-loggedout-pc/user-loggedout-pc.component';
import {AdminLoggedinTelephoneComponent} from './components/nav-bar-profile-component/admin-loggedin-telephone/admin-loggedin-telephone.component';
import {UserLoggedinTelephoneComponent} from './components/nav-bar-profile-component/user-loggedin-telephone/user-loggedin-telephone.component';
import {UserLoggedoutTelephoneComponent} from './components/nav-bar-profile-component/user-loggedout-telephone/user-loggedout-telephone.component';
import {SidebarUserLoggedoutPcComponent} from './components/nav-bar-profile-component/sidebar-user-loggedout-pc/sidebar-user-loggedout-pc.component';
import {SidebarUserLoggedoutTelephoneComponent} from './components/nav-bar-profile-component/sidebar-user-loggedout-telephone/sidebar-user-loggedout-telephone.component';
import {SidebarAdminLoggedinTelephoneComponent} from './components/nav-bar-profile-component/sidebar-admin-loggedin-telephone/sidebar-admin-loggedin-telephone.component';
import {SidebarAdminLoggedinPcComponent} from './components/nav-bar-profile-component/sidebar-admin-loggedin-pc/sidebar-admin-loggedin-pc.component';
import {SidebarUserLoggedinPcComponent} from './components/nav-bar-profile-component/sidebar-user-loggedin-pc/sidebar-user-loggedin-pc.component';
import {SidebarUserLoggedinTelephoneComponent} from './components/nav-bar-profile-component/sidebar-user-loggedin-telephone/sidebar-user-loggedin-telephone.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EffectsModule} from '@ngrx/effects';
import {WelcomeEffect} from './store/effects/WelcomeEffect';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {SignupComponent} from './components/signup/signup.component';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {HelpComponent} from './components/help/help.component';
import {TooltipModule} from "primeng/tooltip";
import { DigitalMediaComponent } from './components/digital-media/digital-media.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    IndexPageComponent,
    NavBarComponent,
    UserLoggedinPcComponent,
    AdminLoggedinPcComponent,
    UserLoggedoutPcComponent,
    AdminLoggedinTelephoneComponent,
    UserLoggedinTelephoneComponent,
    UserLoggedoutTelephoneComponent,
    SidebarUserLoggedoutPcComponent,
    SidebarUserLoggedoutTelephoneComponent,
    SidebarAdminLoggedinTelephoneComponent,
    SidebarAdminLoggedinPcComponent,
    SidebarUserLoggedinPcComponent,
    SidebarUserLoggedinTelephoneComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignupComponent,
    HelpComponent,
    DigitalMediaComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    SidebarModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RippleModule,
    MenuModule,
    StoreModule.forFeature(fromWelcome.welcomeFeatureKey, welcomeReducers),
    EffectsModule.forFeature([WelcomeEffect]),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TooltipModule,
    MatTooltipModule,
  ],
  exports: [NavBarComponent]
})
export class WelcomeModule {
}
