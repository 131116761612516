import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Cart} from '../../model/cart';
import {Store} from '@ngrx/store';
import {selectUser} from '../../../auth/store/selectors/auth.selector';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cartUpdated = new Subject<number>();
  cartData: Cart[];

  constructor(private store: Store) {
  }

  getCart(): Observable<Cart[]> {
    return this.store.select(selectUser).pipe(
      map(user => {
        this.cartData = JSON.parse(localStorage.getItem('cartSubs'))?.filter(c => c.userId === user?.id);
        if (!this.cartData){
          this.cartData = [];
        }
        return this.cartData;
      })
    );
  }

  addToCart(newElem): void {
    if (!this.cartData) {
      this.cartData = [];
    }
    this.cartData.push(newElem);
    localStorage.setItem('cartSubs', JSON.stringify(this.cartData));
    this.cartUpdated.next(this.cartData.length);
  }

  deleteFromCart(): void {
    this.store.select(selectUser).subscribe(
      user => {
        this.cartData = [];
        const allCartData = JSON.parse(localStorage.getItem('cartSubs'));
        let newCart = allCartData?.filter(c => c.userId !== user.id);
        if (!newCart) {
          newCart = [];
        }
        localStorage.setItem('cartSubs', JSON.stringify(newCart));
        this.cartUpdated.next(0);
      }
    );
  }
}
