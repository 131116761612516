import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {AdminHttpUrlResources} from '../../../admin/adminurl/admin-http-url-resources';
import {catchError, map, tap} from 'rxjs/operators';
import {Ticket} from '../../model/ticket';
import {DialogData} from '../../model/dialog.data';
import {Store} from '@ngrx/store';
import {DialogAction} from '../actions/material.dialog.action.types';
import {getSuccessMessage} from '../../../outcome-message/success-message';
import {getErrorMessage} from '../../../outcome-message/error-message';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  tickets: Ticket[] = [];
  ticketClosed = new Subject();

  constructor(private http: HttpClient, private store: Store) {
  }

  getTicket(): Observable<Ticket[]> {
    return this.http.get<Ticket[]>(AdminHttpUrlResources.getTicket()).pipe(
      tap(tickets => this.tickets = tickets.sort((s1, s2) => s2.dataCreazione.localeCompare(s1.dataCreazione))),
      catchError(error => of([]))
    );
  }

  salvaTicket(ticket: Ticket): Observable<DialogData> {
    return this.http.post<DialogData>(AdminHttpUrlResources.salvaTicket(), ticket).pipe(
      map(data => {
        data = {
          code: data.code,
          message: getSuccessMessage('en', 'salvaTicket')
        };
        return data;
      }),
      tap(dialogData => this.store.dispatch(DialogAction.openDialog({dialogData}))),
      catchError(error => {
        const dialogData: DialogData = {
          code: '400',
          message: getErrorMessage('en', 'salvaTicket')
        };
        this.store.dispatch(DialogAction.openDialog({dialogData}));
        return of(dialogData);
      })
    );
  }

  modificaTicket(ticket: Ticket): Observable<DialogData> {
    return this.http.post<DialogData>(AdminHttpUrlResources.modificaTicket(), ticket).pipe(
      map(data => {
        data = {
          code: data.code,
          message: getSuccessMessage('en', 'modificaTicket')
        };
        return data;
      }),
      tap(dialogData => this.store.dispatch(DialogAction.openDialog({dialogData}))),
      catchError(error => {
        const dialogData: DialogData = {
          code: '400',
          message: getErrorMessage('en', 'modificaTicket')
        };
        this.store.dispatch(DialogAction.openDialog({dialogData}));
        return of(dialogData);
      })
    );
  }
}
