<div class="row no-gutters">
  <div class="col-12 px-0" id="first-section">
    <div class="row no-gutters w-100 h-100">
      <div class="ml-auto col-md-5 col-12 my-bg-light h-100 d-flex justify-content-center flex-column">
        <div class="px-5 mt-2">
          <h3 class="text-right text-uppercase">What is PMTest?</h3>
          <p class="text-justify">An exam simulator is a valuable part for your exam preparation. PMTest is a web application that allows you to prepare online with tests, simulations and reports on your performance in order to easily acquire different credentials.</p>
        </div>
        <div class="px-5 mt-2">
          <h3 class="text-right text-uppercase">Why PMTest?</h3>
          <p class="text-justify">Preparing for a certification exam is difficult without a tool to help you understand the weaknesses and improve them. PMTest is an online software that provides a similar environment to the exam, giving you the chance to practice through tests and mock exams.</p>
        </div>
        <div class="px-5 mt-2">
          <h3 class="text-right text-uppercase">How it works?</h3>
          <p class="text-justify">Create a free PMTest account, you will find some tests that allow you to evaluate the quality of both the simulator and the questions.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row no-gutters align-items-start my-3" id="certifications">
  <div class="col-12 p-4 mb-4">
    <h2 class="text-uppercase">Certifications</h2>
  </div>
  <div class="col mx-4 p-4 shadow">
    <div class="text-center">
      <img class="w-50" src="../../../../assets/img/PMP.PNG">
    </div>
    <div class="certification-description-container">
      <h5 class="certification-name">Project Management Professional</h5>
      <p class="text-justify">
        It is the gold standard of project management certification. Recognized and demanded by organizations worldwide, the PMP validates your competence to perform in the role of a project manager, leading and directing projects and teams.
      </p>
    </div>
    <div class="certification-prerequisites-container" (click)="show('pmp')">
      <h5 class="certification-prerequisites-heading">Prerequisites</h5>
      <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !prereqShow.pmp, 'pi-chevron-up': prereqShow.pmp}"></i>
    </div>
    <div class="text-justify" *ngIf="prereqShow.pmp">
      <ul class="mb-0">
        <li>A four-year degree</li>
        <li>36 months leading projects</li>
        <li>35 hours of PM training or CAPM® Certification</li>
      </ul>
      <span class="my-2">
          OR
        </span>
      <ul class="mb-0">
        <li>A high school diploma</li>
        <li>60 months leading projects</li>
        <li>35 hours of PM training or CAPM® Certification</li>
      </ul>
    </div>
    <div class="maintenance-container">
      <h5 class="maintenance-heading">Maintenance</h5>
      <p class="maintenance-description">60 professional development units (PDUs) in PM topics every 3 years</p>
    </div>
    <div>
      <h5 class="text-uppercase m-0">Exam</h5>
      <p class="text-justify font-italic m-0">180 multiple-choice questions in 230 minutes</p>
    </div>
  </div>
  <div class="col mx-4 p-4 shadow">
    <div class="text-center">
      <img class="w-50" src="../../../../assets/img/PMI-ACP.PNG">
    </div>
    <div class="certification-description-container">
      <h5 class="certification-name">Agile Certified Practitioner</h5>
      <p class="text-justify">
        It recognizes your skill, experience and versatility applying agile principles and practices as part of an agile team.
      </p>
    </div>
    <div class="certification-prerequisites-container" (click)="show('pmi')">
      <h5 class="certification-prerequisites-heading">Prerequisites</h5>
      <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !prereqShow.pmi, 'pi-chevron-up': prereqShow.pmi}"></i>
    </div>
    <div class="text-justify" *ngIf="prereqShow.pmi">
      <ul class="mb-0">
        <li>Secondary degree</li>
        <li>21 hours of training in agile practices</li>
        <li>12 months of general project experience within the last 5 years. A current PMP® or PgMP® will satisfy this requirement but is not required to apply for the PMI-ACP.</li>
        <li>8 months of agile project experience within the last 3 years</li>
      </ul>
    </div>
    <div class="maintenance-container">
      <h5 class="maintenance-heading">Maintenance</h5>
      <p class="maintenance-description">30 professional development units (PDUs) in Agile topics every 3 years</p>
    </div>
    <div>
      <h5 class="text-uppercase m-0">Exam</h5>
      <p class="text-justify font-italic m-0">120 multiple-choice questions in 180 minutes</p>
    </div>
  </div>
  <div class="col mx-4 p-4 shadow">
    <div class="text-center">
      <img class="w-50" src="../../../../assets/img/NEW%20CAPM.PNG">
    </div>
    <div class="certification-description-container">
      <h5 class="certification-name">New Certified Associate in Project Management</h5>
      <p class="text-justify">
        Aligned with the new CAPM certification exam released in July 2023, it is designed for who wish to specialize in Project Management and aim to gain experience in project teams.
      </p>
    </div>
    <div class="certification-prerequisites-container" (click)="show('capm')">
      <h5 class="certification-prerequisites-heading">Prerequisites</h5>
      <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !prereqShow.capm, 'pi-chevron-up': prereqShow.capm}"></i>
    </div>
    <div class="text-justify" *ngIf="prereqShow.capm">
      <ul class="mb-0">
        <li>Secondary degree</li>
        <li>23 hours of PM training</li>
      </ul>
    </div>
    <div class="maintenance-container">
      <h5 class="maintenance-heading">Maintenance</h5>
      <p class="maintenance-description">15 professional development units (PDUs) in PM topics every 3 years</p>
    </div>
    <div>
      <h5 class="text-uppercase m-0">Exam</h5>
      <p class="text-justify font-italic m-0">150 multiple-choice questions in 180 minutes</p>
    </div>
  </div>
</div>
<div class="row no-gutters my-3" id="plans">
  <div class="col-12 p-4 mb-4">
    <h2 class="text-uppercase">Plans and Prices</h2>
  </div>
  <div class="col-12">
    <div class="row no-gutters mb-5 w-100 align-items-center">
      <div class="col-md-3 col-6 mx-auto text-center">
        <img src="../../../../assets/img/PMP.PNG" class="w-75">
      </div>
      <div class="col-md-3 col-12 px-4 mt-md-0 mt-2">
        <div class="card shadow w-100">
          <div class="card-header" class="bg-silver">
            <h4 class="p-2 my-0 font-weight-bolder text-center">SILVER</h4>
          </div>
          <div class="card-body text-center">
            <h1 class="card-title pricing-card-title">
              {{'91' | currency:'EUR'}}
            </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li class="font-weight-bold">7 DAYS</li>
              <li class="d-flex align-items-center justify-content-center font-weight-bold">
                50 Hours
                <i pTooltip="You have a total of 50 hours to use for the duration of your subscription" class="pi pi-info-circle ml-2 font-weight-bold"></i>
              </li>
              <li><b>Total questions: 680</b></li>
              <hr>
              <li>24 Learning Test</li>
              <li>4 Small Mock Exam</li>
              <li>2 Mock Exam</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 px-4 mt-md-0 mt-2">
        <div class="card shadow w-100">
          <div class="card-header" class="bg-gold">
            <h4 class="my-0 font-weight-bolder p-2 text-center">GOLD</h4>
          </div>
          <div class="card-body text-center">
            <h1 class="card-title pricing-card-title">
              {{'204' | currency:'EUR'}}
            </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li class="font-weight-bold">30 DAYS</li>
              <li class="d-flex align-items-center justify-content-center font-weight-bold">
                100 Hours
                <i pTooltip="You have a total of 100 hours to use for the duration of your subscription" class="pi pi-info-circle ml-2 font-weight-bold"></i>
              </li>
              <li><b>Total questions: 1400</b></li>
              <hr>
              <li>36 Learning Test</li>
              <li>10 Small Mock Exam</li>
              <li>4 Mock Exam</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 px-4 mt-md-0 mt-2">
        <div class="card shadow w-100">
          <div class="card-header" class="bg-platinum">
            <h4 class="my-0 p-2 font-weight-bolder text-center">PLATINUM</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title text-center">
              {{'281' | currency:'EUR'}}
            </h1>
            <ul class="list-unstyled text-center mt-3 mb-4">
              <li class="font-weight-bold">60 DAYS</li>
              <li class="d-flex align-items-center justify-content-center font-weight-bold">
                195 Hours
                <i pTooltip="You have a total of 195 hours to use for the duration of your subscription" class="pi pi-info-circle ml-2 font-weight-bold"></i>
              </li>
              <li><b>Total questions: 1610</b></li>
              <hr>
              <li>42 Learning Test</li>
              <li>10 Small Mock Exam</li>
              <li>5 Mock Exam</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters mb-5 w-100 align-items-center">
      <div class="col-md-3 col-6 mx-auto text-center">
        <img src="../../../../assets/img/PMI-ACP.PNG" class="w-75">
      </div>
      <div class="col-md-3 col-12 px-4 mt-md-0 mt-2">
        <div class="card shadow w-100">
          <div class="card-header" class="bg-silver">
            <h4 class="p-2 my-0 font-weight-bolder text-center">SILVER</h4>
          </div>
          <div class="card-body text-center">
            <h1 class="card-title pricing-card-title">
              {{'77' | currency:'EUR'}}
            </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li class="font-weight-bold">7 DAYS</li>
              <li class="d-flex align-items-center justify-content-center font-weight-bold">
                50 Hours
                <i pTooltip="You have a total of 50 hours to use for the duration of your subscription" class="pi pi-info-circle ml-2 font-weight-bold"></i>
              </li>
              <li><b>Total questions: 490</b></li>
              <hr>
              <li>20 Learning Test</li>
              <li>3 Small Mock Exam</li>
              <li>2 Mock Exam</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 px-4 mt-md-0 mt-2">
        <div class="card shadow w-100">
          <div class="card-header" class="bg-gold">
            <h4 class="my-0 font-weight-bolder p-2 text-center">GOLD</h4>
          </div>
          <div class="card-body text-center">
            <h1 class="card-title pricing-card-title">
              {{'183' | currency:'EUR'}}
            </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li class="font-weight-bold">30 DAYS</li>
              <li class="d-flex align-items-center justify-content-center font-weight-bold">
                100 Hours
                <i pTooltip="You have a total of 100 hours to use for the duration of your subscription" class="pi pi-info-circle ml-2 font-weight-bold"></i>
              </li>
              <li><b>Total questions: 1010</b></li>
              <hr>
              <li>30 Learning Test</li>
              <li>10 Small Mock Exam</li>
              <li>3 Mock Exam</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 px-4 mt-md-0 mt-2">
        <div class="card shadow w-100">
          <div class="card-header" class="bg-platinum">
            <h4 class="my-0 p-2 font-weight-bolder text-center">PLATINUM</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title text-center">
              {{'253' | currency:'EUR'}}
            </h1>
            <ul class="list-unstyled text-center mt-3 mb-4">
              <li class="font-weight-bold">60 DAYS</li>
              <li class="d-flex align-items-center justify-content-center font-weight-bold">
                195 Hours
                <i pTooltip="You have a total of 195 hours to use for the duration of your subscription" class="pi pi-info-circle ml-2 font-weight-bold"></i>
              </li>
              <li><b>Total questions: 1130</b></li>
              <hr>
              <li>30 Learning Test</li>
              <li>10 Small Mock Exam</li>
              <li>4 Mock Exam</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters mb-5 w-100 align-items-center">
      <div class="col-md-3 col-6 mx-auto text-center">
        <img src="../../../../assets/img/NEW%20CAPM.PNG" class="w-75">
      </div>
      <div class="col-md-3 col-12 px-4 mt-md-0 mt-2">
        <div class="card shadow w-100">
          <div class="card-header" class="bg-silver">
            <h4 class="p-2 my-0 font-weight-bolder text-center">SILVER</h4>
          </div>
          <div class="card-body text-center">
            <h1 class="card-title pricing-card-title">
              {{'75' | currency:'EUR'}}
            </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li class="font-weight-bold">7 DAYS</li>
              <li class="d-flex align-items-center justify-content-center font-weight-bold">
                50 Hours
                <i pTooltip="You have a total of 50 hours to use for the duration of your subscription" class="pi pi-info-circle ml-2 font-weight-bold"></i>
              </li>
              <li><b>Total questions: 475</b></li>
              <hr>
              <li>5 Learning Test</li>
              <li>6 Small Mock Exam</li>
              <li>1 Mock Exam</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 px-4 mt-md-0 mt-2">
        <div class="card shadow w-100">
          <div class="card-header" class="bg-gold">
            <h4 class="my-0 font-weight-bolder p-2 text-center">GOLD</h4>
          </div>
          <div class="card-body text-center">
            <h1 class="card-title pricing-card-title">
              {{'161' | currency:'EUR'}}
            </h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li class="font-weight-bold">30 DAYS</li>
              <li class="d-flex align-items-center justify-content-center font-weight-bold">
                100 Hours
                <i pTooltip="You have a total of 100 hours to use for the duration of your subscription" class="pi pi-info-circle ml-2 font-weight-bold"></i>
              </li>
              <li><b>Total questions: 750</b></li>
              <hr>
              <li>10 Learning Test</li>
              <li>8 Small Mock Exam</li>
              <li>2 Mock Exam</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 px-4 mt-md-0 mt-2">
        <div class="card shadow w-100">
          <div class="card-header" class="bg-platinum">
            <h4 class="my-0 p-2 font-weight-bolder text-center">PLATINUM</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title text-center">
              {{'224' | currency:'EUR'}}
            </h1>
            <ul class="list-unstyled text-center mt-3 mb-4">
              <li class="font-weight-bold">60 DAYS</li>
              <li class="d-flex align-items-center justify-content-center font-weight-bold">
                195 Hours
                <i pTooltip="You have a total of 195 hours to use for the duration of your subscription" class="pi pi-info-circle ml-2 font-weight-bold"></i>
              </li>
              <li><b>Total questions: 875</b></li>
              <hr>
              <li>15 Learning Test</li>
              <li>10 Small Mock Exam</li>
              <li>2 Mock Exam</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row no-gutters my-3" id="features">
  <div class="col-12 p-4 mb-4">
    <h2 class="text-uppercase">Main Features</h2>
  </div>
  <div class="col-12 px-4 mx-auto mb-4">
    <fieldset class="p-4 border border-dark">
      <legend class="py-2 px-5 bg-dark text-light text-uppercase">Questions</legend>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('questions','language')">
          <h5 class="text-uppercase">Language</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.questions.language, 'pi-chevron-up': mainShow.questions.language}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.questions.language">
          The questions are in English but the Italian version is also available.
        </div>
      </div>
      <hr>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('questions','comment')">
          <h5 class="text-uppercase">Comment</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.questions.comment, 'pi-chevron-up': mainShow.questions.comment}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.questions.comment">
          Each question has a comment (in English and Italian) that allows the candidate to understand the structure of the question and the justification about correct and incorrect answers.
        </div>
      </div>
      <hr>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('questions','questionOrigin')">
          <h5 class="text-uppercase">Question Origin</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.questions.questionOrigin, 'pi-chevron-up': mainShow.questions.questionOrigin}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.questions.questionOrigin">
          Questions are produced by a team of qualified experts who have been following the dynamics of change in certifications for several decades.
        </div>
      </div>
      <hr>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('questions','questionNumbers')">
          <h5 class="text-uppercase">Question Numbers</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.questions.questionNumbers, 'pi-chevron-up': mainShow.questions.questionNumbers}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.questions.questionNumbers">
          <ul>
            <li>PMP: 2210+</li>
            <li>PMI-ACP: 1250+</li>
            <li>CAPM: 1050+</li>
          </ul>
        </div>
      </div>
      <hr>
    </fieldset>
  </div>
  <div class="col-12 px-4 mx-auto mb-4">
    <fieldset class="p-4 border border-dark">
      <legend class="py-2 px-5 bg-dark text-light text-uppercase">Main Types of Tests</legend>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('tests','lt')">
          <h5 class="text-uppercase">Learning Tests (LT)</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.tests.lt, 'pi-chevron-up': mainShow.tests.lt}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.tests.lt">
          LT are short tests (5 questions) which aim to ensure maximum understanding of the concepts underlying the questions examined. In fact, you pass to the next LT only if you answer all the test questions well for 2 consecutive times.
        </div>
      </div>
      <hr>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('tests','me')">
          <h5 class="text-uppercase">Mock Exam (ME)</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.tests.me, 'pi-chevron-up': mainShow.tests.me}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.tests.me">
          ME are realistic exam simulations. In fact, the time, the number of questions and the distribution of the questions are identical to the real exam.
        </div>
      </div>
      <hr>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('tests','sme')">
          <h5 class="text-uppercase">Small Mock Exam (SME)</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.tests.sme, 'pi-chevron-up': mainShow.tests.sme}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.tests.sme">
          SME are tests with 50 questions that allow the candidate to test their knowledge on specific topics.
        </div>
      </div>
      <hr>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('tests','repeat')">
          <h5 class="text-uppercase">Can a user repeat a test?</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.tests.repeat, 'pi-chevron-up': mainShow.tests.repeat}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.tests.repeat">
          Each test can be run several times, at each execution PMTest repeats the same questions but with the answers in a different sequence.
        </div>
      </div>
      <hr>
    </fieldset>
  </div>
  <div class="col-12 px-4 mx-auto mb-4">
    <fieldset class="p-4 border border-dark">
      <legend class="py-2 px-5 bg-dark text-light text-uppercase">Exam Report</legend>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('report','errata')">
          <h5 class="text-uppercase">Errata Report</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.report.errata, 'pi-chevron-up': mainShow.report.errata}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.report.errata">
          At the end of each SME and ME, candidate can examine following information:
          <ul>
            <li>average time taken to answer the questions</li>
            <li>total execution time</li>
            <li>overall percentage of correct answers</li>
            <li>percentage of correct answers for domain</li>
          </ul>
        </div>
      </div>
      <hr>
      <div>
        <div class="d-flex align-items-center" (click)="showMain('report','answers')">
          <h5 class="text-uppercase">Answers</h5>
          <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': !mainShow.report.answers, 'pi-chevron-up': mainShow.report.answers}"></i>
        </div>
        <div class="my-3 text-justify" *ngIf="mainShow.report.answers">
          At the end of the test, the candidate can view their answers individually, examine the mistakes made and read the comment to understand the reasons for the correct answers and their mistakes.
        </div>
      </div>
      <hr>
    </fieldset>
  </div>
</div>
