import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpUrlResources} from '../../../url/http-url-resources';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static codeVerifica: string;

  constructor(private http: HttpClient) {
  }

  public login(username: string, password: string): Observable<HttpResponse<any>> {
    return this.http.post(HttpUrlResources.loginUrl(), {username, password}, {observe: 'response'});
  }

  public logout() {
    return this.http.post(HttpUrlResources.logoutUrl(), {});
  }

  public setToken(token: string): void {
    localStorage.setItem('tkn', token);
  }

  public setCodeVerifica(code: string): void {
    AuthService.codeVerifica = code;
    localStorage.setItem('codeVerifica', code);
  }


  public getToken(): string {
    return localStorage.getItem('tkn');
  }

  public deleteToken(): void {
    localStorage.removeItem('tkn');
  }
}
