import {createAction, props} from '@ngrx/store';
import {DialogData} from '../../../shared/model/dialog.data';
import {Signup} from '../../model/signup';

export const openCloseSideBar = createAction('[Menu Button] Open Side Bar');

export const closeSideBar = createAction('[Menu Button] Close Side Bar');

export const sendResetPasswordEmail = createAction('[Forgot Password Component] Server Try To Send Email For Reset Password',
  props<{ email: string }>());

export const sendResetPasswordEmailSuccess = createAction('[Welcome Effect] Successfully Send Email For Password Generation',
  props<{ dialogData: DialogData }>());

export const sendResetPasswordEmailFail = createAction('[Welcome Effect] Fail To Send Email For Password Generation',
  props<{ dialogData: DialogData }>());

export const resetPassword = createAction('[Reset Password Component] Reset Password',
  props<{ token: string }>());

export const resetPasswordSuccess = createAction('[Welcome Effect] Successfully Reset Password',
  props<{ dialogData: DialogData }>());

export const resetPasswordFail = createAction('[Welcome Effect] Fail To Reset Password',
  props<{ dialogData: DialogData }>());

export const registration = createAction('[Signup Component] Register A Student',
  props<{ signup: Signup }>()
);

export const registrationFail = createAction('[WelcomeEffect] Fail To Register',
  props<{ dialogData: DialogData }>()
);

export const registrationSuccess = createAction('[WelcomeEffect] Registration Succeded',
  props<{dialogData: DialogData }>()
);
