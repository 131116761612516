<div class="row no-gutters mt-3">
  <div class="col-12">
    <h2 class="p-4" *ngIf="selectedLang == 'ITA'">Digital Media per il mantenimento delle certificazioni PMI</h2>
    <h2 class="p-4" *ngIf="selectedLang == 'ENG'">Digital Media for the maintenance of PMI certifications</h2>
  </div>
  <div class="col-12 px-0">
    <div class="row no-gutters align-items-center">
      <div class="col-md-4 col-12 text-center">
        <a target="_blank" href="https://www.pmi.org/learning/training-development/talent-triangle">
          <img class="w-75" src="../../../../assets/img/digital-media/full.png">
        </a>
      </div>
      <div class="col-md-8 col-12">
        <p class="p-4 mb-0 text-justify" *ngIf="selectedLang == 'ITA'">
          ELIS offre on demand una serie di contenuti per approfondire temi specifici legati alle pratiche e alla teoria del Project Management. Lo studio attraverso la fruizione dei Digital Media, insieme ad un’attività di test correlati, permette di acquisire 2 PDU per ogni contenuto acquistato. I PDU verranno attestati da ELIS e saranno utilizzabili nei cicli di mantenimento delle certificazioni PMI. Tutti i Digital Media presenti nel catalogo possono essere utilizzati e attestati per il mantenimento delle seguenti certificazioni PMI:
        </p>
        <p class="p-4 mb-0 text-justify" *ngIf="selectedLang == 'ENG'">
          ELIS offers on demand a series of contents to deepen specific topics related to the practices and theory of Project Management. Studying through these Digital Media, together with a related test activity, allows you to acquire 2 PDUs for each content purchased. The PDUs will be certified by ELIS and can be used in PMI certification maintenance cycles. All Digital Media in the catalogue can be used and attested for the maintenance of the following PMI certifications:
        </p>
        <div class="d-flex p-4 align-items-center justify-content-between">
          <div class="border border-secondary shadow rounded p-3 d-flex align-items-center justify-content-center text-center">PMP</div>
          <div class="border border-secondary shadow rounded p-3 d-flex align-items-center justify-content-center text-center">PMI-ACP</div>
          <div class="border border-secondary shadow rounded p-3 d-flex align-items-center justify-content-center text-center">PgMP</div>
          <div class="border border-secondary shadow rounded p-3 d-flex align-items-center justify-content-center text-center">PMI-RMP</div>
          <div class="border border-secondary shadow rounded p-3 d-flex align-items-center justify-content-center text-center">PMI-PBA</div>
          <div class="border border-secondary shadow rounded p-3 d-flex align-items-center justify-content-center text-center">CAPM</div>
        </div>
        <p class="p-4 text-justify" *ngIf="selectedLang == 'ITA'">
          Scegli gli argomenti che meglio si adattano alle tue necessità di studio o di approfondimento delle tue skill professionali. Quando completerai correttamente il test di verifica riceverai la documentazione da ELIS utile alla registrazione dei PDU sul sito del PMI. Per avere maggiori informazioni e come poter acquistare i contenuti contatta <a href="mailto:l.pierri@elis.org">l.pierri@elis.org</a> indicando i codici dei Digital Media che ti interessano. Verrai abilitato alla fruizione del contenuto multimediale e all’accesso del relativo test online di verifica.
        </p>
        <p class="p-4 text-justify" *ngIf="selectedLang == 'ENG'">
          Choose the topics that best suit your needs to study or to improve your professional skills. When you have successfully completed the verification test, you will receive the documentation from ELIS that can be used for registering the PDUs on the PMI website. For more information on the Digital Media and how to buy the contents, contact <a href="mailto:l.pierri@elis.org">l.pierri@elis.org</a>, specifying the codes of the Digital Media that interest you. You will be immediately enabled to the use of the multimedia content and to the access to the related online verification test.
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 mt-3">
    <h3 class="px-4 py-2 mb-0" *ngIf="selectedLang == 'ITA'">Guida all’acquisto e all’utilizzo dei Digital Media</h3>
    <h3 class="px-4 py-2 mb-0" *ngIf="selectedLang == 'ENG'">Guide to the purchase and use of Digital Media</h3>
    <p class="p-4 mb-0 text-justify" *ngIf="selectedLang == 'ITA'">Per poter acquistare e utilizzare i contenuti segui questi step:</p>
    <p class="p-4 mb-0 text-justify" *ngIf="selectedLang == 'ENG'">To purchase and use the content, please follow these steps:</p>
    <div class="px-4 mb-4" *ngIf="selectedLang == 'ENG'">
      <ol>
        <li>Choose the contents that interest you</li>
        <li>Write to <a href="mailto:infopmtest@elis.org">infopmtest@elis.org</a> to tell us your choice or to have more information.</li>
        <li>Our staff will give you access to the chosen content.</li>
        <li>When you complete to watch or read the acquired content, send an email to <a href="mailto:infopmtest@elis.org">infopmtest@elis.org</a>, so our staff will send the related test you are able to repeat it many times.</li>
        <li>When you pass the test (at least 70% of success), send an email to <a href="mailto:infopmtest@elis.org">infopmtest@elis.org</a> and you will receive instructions for submitting earned PDUs</li>
      </ol>
    </div>
    <div class="px-4 mb-4" *ngIf="selectedLang == 'ITA'">
      <ol>
        <li>Scegli i contenuti che ti interessano</li>
        <li>Scrivi a <a href="mailto:infopmtest@elis.org">infopmtest@elis.org</a> per comunicarci la tua scelta o per avere maggiori informazioni.</li>
        <li>Il nostro staff ti darà l'accesso ai contenuti scelti.</li>
        <li>Quando finisci di guardare o leggere il contenuto acquisito, invia un'e-mail a <a href="mailto:infopmtest@elis.org">infopmtest@elis.org</a>, e il nostro staff invierà il relativo test che avrai la possibilità di ripetere più volte.</li>
        <li>Quando supererai il test (almeno il 70% di risposte esatte), invia un'e-mail a <a href="mailto:infopmtest@elis.org">infopmtest@elis.org</a> e riceverai le istruzioni per l’uso dei PDU guadagnati.</li>
      </ol>
    </div>
  </div>
  <div class="col-md-4 col-12 p-3">
    <h3 class="text-center mb-3">Business Acumen</h3>
    <div class="text-center image-container">
      <a target="_blank" href="https://www.pmi.org/learning/training-development/talent-triangle">
        <img class="w-50" src="../../../../assets/img/digital-media/ba.png">
      </a>
    </div>
    <div class="border rounded d-flex flex-column align-items-center p-3 my-3">
      <span>Benefits Management and Realization</span>
      <span>Benefits Models and Structures</span>
      <span>Competitive Analysis</span>
      <span>Customer Relationships and Satisfaction</span>
      <span>Industry Domain Knowledge</span>
      <span>Legal and Regulatory Compliance</span>
      <span>Market Awareness</span>
      <span>Function-Specific Knowledge</span>
      <span>Strategic Planning, Analysis, Alignment</span>
    </div>
  </div>
  <div class="col-md-4 col-12 p-3">
    <h3 class="text-center mb-3">Ways of Working</h3>
    <div class="text-center image-container">
      <a target="_blank" href="https://www.pmi.org/learning/training-development/talent-triangle">
        <img class="w-50" src="../../../../assets/img/digital-media/wow.png">
      </a>
    </div>
    <div class="border rounded d-flex flex-column align-items-center p-3 my-3">
      <span>Agile and Hyper Agile</span>
      <span>Hybrid</span>
      <span>Design Thinking</span>
      <span>Transformation</span>
      <span>Data Gathering and Modeling</span>
      <span>Earned Value Management</span>
      <span>Governance</span>
      <span>Performance Management</span>
      <span>Requirements Management and Traceability</span>
      <span>Risk Management</span>
      <span>Schedule Management</span>
      <span>Scope Management</span>
      <span>Time, Budget and Cost Estimation</span>
    </div>
  </div>
  <div class="col-md-4 col-12 p-3">
    <h3 class="text-center mb-3">Power Skills</h3>
    <div class="text-center image-container">
      <a target="_blank" href="https://www.pmi.org/learning/training-development/talent-triangle">
        <img class="w-50" src="../../../../assets/img/digital-media/ps.png">
      </a>
    </div>
    <div class="border rounded d-flex flex-column align-items-center p-3 my-3">
      <span>Leadership</span>
      <span>Active Listening</span>
      <span>Communication</span>
      <span>Adaptability</span>
      <span>Brainstorming</span>
      <span>Coaching and Mentoring</span>
      <span>Conflict Management</span>
      <span>Emotional Intelligence</span>
      <span>Influencing</span>
      <span>Interpersonal Skills</span>
      <span>Negotiation</span>
      <span>Problem Solving</span>
      <span>Teamwork</span>
    </div>
  </div>
  <div class="col-12 p-3">
    <h3 *ngIf="selectedLang == 'ITA'">Elenco dei Digital Media</h3>
    <h3 *ngIf="selectedLang == 'ENG'">List of Digital Media:</h3>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" class="w-15">Competence Area</th>
          <th scope="col" class="w-20">Title</th>
          <th scope="col" class="w-35">Description</th>
          <th scope="col">PDU</th>
          <th scope="col">Type</th>
          <th scope="col"></th>
          <th scope="col">Author</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of digitalMedia">
          <td>
            <a href="https://www.pmi.org/learning/training-development/talent-triangle" *ngIf="d.competenceArea == 'Power Skills'">
              <img matTooltip="Power Skills" class="w-100" src="../../../../assets/img/digital-media/ps.png">
            </a>
            <a href="https://www.pmi.org/learning/training-development/talent-triangle" *ngIf="d.competenceArea == 'Ways of Working'">
              <img matTooltip="Ways of Working" class="w-100" src="../../../../assets/img/digital-media/wow.png">
            </a>
            <a href="https://www.pmi.org/learning/training-development/talent-triangle" *ngIf="d.competenceArea == 'Business Acumen'">
              <img matTooltip="Business Acumen" class="w-100" src="../../../../assets/img/digital-media/ba.png">
            </a>
          </td>
          <td>
            <strong>{{d.title | titlecase}}</strong>
          </td>
          <td>
            <p class="text-justify">{{selectedLang == 'ITA' ? d.descriptionIta : d.descriptionEng}}</p>
          </td>
          <td class="text-center">{{d.pdu}}</td>
          <td>{{d.type | titlecase}}</td>
          <td>
            <img *ngIf="d.language == 'ITA'" src="../../../../assets/img/it.png" class="digital-icon">
            <img *ngIf="d.language == 'ENG'" src="../../../../assets/img/uk.png" class="digital-icon">
          </td>
          <td>
            <a class="d-flex align-items-center" target="_blank" [href]="d.author.link">
              <img class="digital-icon" src="../../../../assets/img/digital-media/linkedin-square-icon.svg">
              <span class="ml-2">{{d.author.name | titlecase}}</span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <small class="my-2 text-center font-weight-bold d-block">
      “PMI”, “PMP”, “CAPM”, “PMI-PBA”, “PMI-RMP”, “PMI-ACP” are registered marks of the Project Management Institute, Inc.
      “The PMI Talent Triangle” and the PMI Talent Triangle image are marks of Project Management Institute, Inc.”
    </small>
  </div>
</div>
<button class="translate btn btn-dark d-flex align-items-center" type="button" (click)="changeLang()">
  <img class="translate-icon mr-2" style="color: white" src="../../../../assets/img/digital-media/language-icon.png">
  <span *ngIf="selectedLang == 'ITA'">IT <span class="pi pi-arrow-right"></span> ENG</span>
  <span *ngIf="selectedLang == 'ENG'">ENG <span class="pi pi-arrow-right"></span> IT</span>
</button>
