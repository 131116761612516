import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DialogData} from '../../../shared/model/dialog.data';
import {Observable} from 'rxjs';
import {HttpUrlResources} from '../../../url/http-url-resources';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(private http: HttpClient) {
  }

  public sendResetPasswordEmail(email: string): Observable<DialogData> {
    return this.http.post<DialogData>(HttpUrlResources.sendResetPasswordEmail(), {email});
  }

  public resetPassword(token: string): Observable<DialogData> {
    return this.http.post<DialogData>(HttpUrlResources.resetPassword(), {token});

  }
}
