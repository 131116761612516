import {Component, OnInit} from '@angular/core';
import {AppState} from '../../../reducers';
import {Store} from '@ngrx/store';
import {WelcomeAction} from '../../store/actions/welcome.action.types';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  email: string;

  constructor(private store: Store<AppState>) {
  }


  ngOnInit(): void {
  }

  public resetPassword() {
    if (!this.email || this.email.trim().length < 5) {
      return;
    }
    this.store.dispatch(WelcomeAction.sendResetPasswordEmail({email: this.email}));
  }

}
