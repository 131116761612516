import {Component, Inject, OnInit} from '@angular/core';
import {DialogData} from '../../model/dialog.data';
import {Router} from '@angular/router';
import {CartService} from '../../../user/store/services/cart.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  constructor(
    public router: Router,
    private cartService: CartService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
  }

  close() {
    if (location.href.includes('forgotpassword')) {
      this.router.navigateByUrl('login');
    }
    this.dialogRef.close();
    if (this.data.cause === 'purchase' && this.data.code === '200'){
      this.router.navigateByUrl('user/index');
      this.cartService.deleteFromCart();
    }
  }
}
