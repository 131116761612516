import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ResetPasswordService} from '../services/reset-password.service';
import {WelcomeAction} from '../actions/welcome.action.types';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {DialogAction} from '../../../shared/store/actions/material.dialog.action.types';
import {LoadingIndicator} from '../../../shared/store/actions/action-types';
import {HttpErrorResponse} from '@angular/common/http';
import {SignupService} from '../services/signup.service';
import {DialogData} from '../../../shared/model/dialog.data';
import {Router} from '@angular/router';
import {getErrorMessage} from '../../../outcome-message/error-message';
import {getSuccessMessage} from "../../../outcome-message/success-message";

@Injectable()
export class WelcomeEffect {

  constructor(private actions$: Actions, private resetPasswordService: ResetPasswordService,
              private signup: SignupService, private router: Router) {
  }

  sendEmailForResetPassword = createEffect(() => this.actions$
    .pipe(
      ofType(WelcomeAction.sendResetPasswordEmail),
      concatMap(action => {
        return this.resetPasswordService.sendResetPasswordEmail(action.email)
          .pipe(
            map(dialogData => {
              const modifyDialog: DialogData = {
                code: '200',
                message: `The token for requesting a new password has been generated for the user with username
                ${action.email} and an email was sent with the data`
              };
              return WelcomeAction.sendResetPasswordEmailSuccess({dialogData: modifyDialog});
            }),
            catchError((error: HttpErrorResponse) => of(WelcomeAction.sendResetPasswordEmailFail({
              dialogData: {
                code: error.error?.code || '400',
                message: getErrorMessage('en', 'sendEmailForResetPassword')
              }
            })))
          );
      })
    )
  );

  openDialog$ = createEffect(() => this.actions$
    .pipe(
      ofType(
        WelcomeAction.sendResetPasswordEmailSuccess,
        WelcomeAction.sendResetPasswordEmailFail,
        WelcomeAction.resetPasswordFail,
        WelcomeAction.resetPasswordSuccess,
        WelcomeAction.registrationFail,
        WelcomeAction.registrationSuccess
      ),
      map(action => {
        return DialogAction.openDialog({dialogData: action.dialogData});
      })
    )
  );

  hideSpinner$ = createEffect(() => this.actions$.pipe(
    ofType(
      WelcomeAction.sendResetPasswordEmailFail,
      WelcomeAction.sendResetPasswordEmailSuccess,
      WelcomeAction.resetPasswordFail,
      WelcomeAction.resetPasswordSuccess,
      WelcomeAction.registrationSuccess,
      WelcomeAction.registrationFail
    ),
    map(action => LoadingIndicator.hideLoadingIndicator())
    )
  );

  showSpinner$ = createEffect(() => this.actions$.pipe(
    ofType(
      WelcomeAction.sendResetPasswordEmail,
      WelcomeAction.resetPassword,
      WelcomeAction.registration
    ),
    map(action => LoadingIndicator.showLoadingIndicator()))
  );

  resetPassword$ = createEffect(() => this.actions$
    .pipe(
      ofType(WelcomeAction.resetPassword),
      concatMap(action => {
        return this.resetPasswordService.resetPassword(action.token)
          .pipe(
            map(dialogData => {
              dialogData.message = 'A new password has been generated for the user and an email has been sent with the data.';
              return WelcomeAction.resetPasswordSuccess({dialogData})
            }),
            catchError((error: HttpErrorResponse) => {
              return of(WelcomeAction.resetPasswordFail(
                {
                  dialogData: {
                    code: error.error?.code || '400',
                    message: getErrorMessage('en', 'resetPassword')
                  }
                }
              ));
            })
          );
      })
    )
  );

  registration$ = createEffect(() => this.actions$.pipe(
    ofType(WelcomeAction.registration),
    concatMap(action => this.signup.signUp(action.signup).pipe(
      map(result => {
        const dialogData: DialogData = {
          code: '200',
          message: getSuccessMessage('en', 'signup')
        };
        return WelcomeAction.registrationSuccess({dialogData});
      }),
      catchError((error: HttpErrorResponse) => {
        return of(WelcomeAction.registrationFail({
          dialogData: {
            message: getErrorMessage('en', 'registration'),
            code: error?.error?.code || '400'
          }
        }));
      })
      )
    )
    )
  );


  redirectToLoginWhenRegistartionSucceded$ = createEffect(() => this.actions$.pipe(
    ofType(WelcomeAction.registrationSuccess),
    tap(() => {
      this.router.navigateByUrl('/login');
    })
    ), {dispatch: false}
  );

}
