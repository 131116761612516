import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthService} from '../services/auth.service';
import {AuthActions} from '../actions/action-types';
import {catchError, concatMap, filter, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {User} from '../../model/user';
import {JwtDecodedObject} from '../../model/jwt.decoded.object';
import {LoadingIndicator} from '../../../shared/store/actions/action-types';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {selectUser} from '../selectors/auth.selector';
import {Router} from '@angular/router';
import {DialogAction} from '../../../shared/store/actions/material.dialog.action.types';
import {DialogData} from '../../../shared/model/dialog.data';
import {HttpErrorResponse} from '@angular/common/http';
import {getErrorMessage} from '../../../outcome-message/error-message';
import {jwtDecode} from "jwt-decode";

@Injectable()
export class LoginEffect {

  constructor(private actions$: Actions, private authService: AuthService, private store: Store<AppState>, private router: Router) {
  }

  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    concatMap(action => this.authService.login(action.userCredential.username, action.userCredential.password).pipe(
      map(response => {
        let token = response.headers.get('Authorization');
        if (token) {
          token = token.replace('Bearer ', '');
        }
        this.authService.setToken(token);
        const decoded: JwtDecodedObject = jwtDecode(token);
        const user: User = {
          id: decoded.id,
          certificazione1: decoded.certificazione1,
          ruolo1: decoded.ruolo1,
          certificazione2: decoded.certificazione2,
          ruolo2: decoded.ruolo2,
          certificazione3: decoded.certificazione3,
          ruolo3: decoded.ruolo3,
          certificazione4: decoded.certificazione4,
          ruolo4: decoded.ruolo4,
          exp: decoded.exp,
          username: decoded.sub,
          ip: decoded.ip,
          userAgent: decoded.userAgent
        };
        const codeVerifica = response.headers.get('codeVerifica');
        //AuthService.codeVerifica = response.headers.get('codeVerifica');
        this.authService.setCodeVerifica(codeVerifica);
        //localStorage.setItem('codeVerifica', AuthService.codeVerifica);
        return user;
      }),
      map(user => AuthActions.loginSuccess({user})),
      catchError((error: HttpErrorResponse) => {
        if (error?.status === 0) {
          return of(AuthActions.loginFailForInternetConnection());
        } else {
          return of(AuthActions.loginFail());
        }

      })
    ))
    )
  );

  hideSpinner$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginSuccess, AuthActions.loginFail, AuthActions.loginFailForInternetConnection),
    map(action => LoadingIndicator.hideLoadingIndicator())
    )
  );

  showSpinner$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    map(action => LoadingIndicator.showLoadingIndicator()))
  );

  routingToIndex$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginSuccess),
    concatMap(() => this.store.select(selectUser)),
    filter(user => user !== undefined && user !== null),
    tap((u) => {
      if (u.ruolo1 === '1' && u.ruolo2 === '1' && u.ruolo3 === '1' && u.ruolo4 === '1') {
        this.router.navigateByUrl('/admin/index');
      } else {
        this.router.navigateByUrl('/user/index');
      }
    })
    ), {dispatch: false}
  );


  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    concatMap(() => this.authService.logout()
      .pipe(
        map(responseBody => AuthActions.logOutSuccess()),
        catchError((error: HttpErrorResponse) => {
          const dialogData: DialogData = {code: '400', message: getErrorMessage('en', 'logout')};
          return of(AuthActions.logOutFail({dialogData}));
        })
      ))
    )
  );

  logoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    tap(() => {
      AuthService.codeVerifica = undefined;
      localStorage.removeItem('codeVerifica');
      this.authService.deleteToken();
      this.router.navigateByUrl('/login');
    })
    ), {dispatch: false}
  );

  loginFailForInternetConnection$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.loginFailForInternetConnection),
      map((action) => DialogAction.openDialog({
        dialogData: {
          code: '400',
          message: getErrorMessage('en', 'loginFailForInternetConnection')
        }
      }))
    )
  );
}

