<!-- Side Bar -->
<div *ngIf="(isNavbarOpen$ | async)">
  <p-sidebar [dismissible]="false" [style]="{'overflow-y': 'scroll'}" [baseZIndex]="3" [blockScroll]="true"
             [visible]="isSideBarOpen$ | async" styleClass="p-sidebar-sm" position="right" [showCloseIcon]="false">
    <div class="text-right mb-3">
      <button [style]="'width:28px;height:28px'" (click)="closeSideBar()" pButton pRipple type="button"
              icon="pi pi-times"
              class="p-button-rounded p-button-outlined p-button-danger"></button>
    </div>
    <div *ngIf="(isLoggedIn$ | async);else loggegOutSideBar">
      <!-- SideBar NewTemplate for Logged  User -->
      <div *ngIf="(isTabletOrLarger$ | async).matches;else loggedInSideBarPhone;">
        <div>
          <!-- Admin -->
          <div *ngIf="(user$ | async).ruolo1 == '1' && (user$ | async).ruolo2 == '1' && (user$ | async).ruolo3 == '1' && (user$ | async).ruolo4 == '1'">
            <app-sidebar-admin-loggedin-pc></app-sidebar-admin-loggedin-pc>
          </div>
          <!-- User -->
          <div *ngIf="(user$ | async).ruolo1 != '1' && (user$ | async).ruolo2 != '1' && (user$ | async).ruolo3 != '1' && (user$ | async).ruolo4 != '1'">
            <app-sidebar-user-loggedin-pc></app-sidebar-user-loggedin-pc>
          </div>
        </div>
      </div>
      <ng-template #loggedInSideBarPhone>
        <div>
          <!-- Admin -->
          <div *ngIf="(user$ | async).ruolo1 == '1' && (user$ | async).ruolo2 == '1' && (user$ | async).ruolo3 == '1' && (user$ | async).ruolo4 == '1'">
            <app-sidebar-admin-loggedin-telephone></app-sidebar-admin-loggedin-telephone>
          </div>
          <!-- User -->
          <div *ngIf="(user$ | async).ruolo1 != '1' && (user$ | async).ruolo2 != '1' && (user$ | async).ruolo3 != '1' && (user$ | async).ruolo4 != '1'">
            <app-sidebar-user-loggedin-telephone></app-sidebar-user-loggedin-telephone>
          </div>
        </div>
      </ng-template>
    </div>
    <ng-template #loggegOutSideBar>
      <!--Computer Logged out SideBar -->
      <div *ngIf="(isTabletOrLarger$ | async).matches;else loggedOutSideBarPhone;">
        <app-sidebar-user-loggedout-pc></app-sidebar-user-loggedout-pc>
      </div>
      <!--Telephone Logged out SideBar -->
      <ng-template #loggedOutSideBarPhone>
        <app-sidebar-user-loggedout-telephone></app-sidebar-user-loggedout-telephone>
      </ng-template>
    </ng-template>
  </p-sidebar><!-- End of the SideBar -->

  <!--User LoggedIn NewTemplate -->
  <!-- Pc LoogedIn NewTemplate -->
  <div *ngIf="(isLoggedIn$ | async);else loggedOut">
    <div *ngIf="(isTabletOrLarger$ | async).matches;else loginTelephoneNavBar;">
      <div>
        <div *ngIf="(user$ | async).ruolo1 == '1' && (user$ | async).ruolo2 == '1' && (user$ | async).ruolo3 == '1' && (user$ | async).ruolo4 == '1'" class="border-bottom">
          <app-admin-loggedin-pc></app-admin-loggedin-pc>
        </div>
        <div *ngIf="(user$ | async).ruolo1 != '1' && (user$ | async).ruolo2 != '1' && (user$ | async).ruolo3 != '1' && (user$ | async).ruolo4 != '1'" class="border-bottom">
          <app-user-loggedin-pc></app-user-loggedin-pc>
        </div>
      </div>
    </div>
    <!-- Telephone LoggedIn NewTemplate -->
    <ng-template #loginTelephoneNavBar>
      <div>
        <div *ngIf="(user$ | async).ruolo1 == '1' && (user$ | async).ruolo2 == '1' && (user$ | async).ruolo3 == '1' && (user$ | async).ruolo4 == '1'">
          <app-admin-loggedin-telephone></app-admin-loggedin-telephone>
        </div>
        <div *ngIf="(user$ | async).ruolo1 != '1' && (user$ | async).ruolo2 != '1' && (user$ | async).ruolo3 != '1' && (user$ | async).ruolo4 != '1'">
          <app-user-loggedin-telephone></app-user-loggedin-telephone>
        </div>
      </div>
    </ng-template>
  </div>
  <!--User Not Logged NewTemplate -->
  <ng-template #loggedOut>
    <!-- Pc LoogedOut NewTemplate -->
    <div *ngIf="(isTabletOrLarger$ | async).matches;else logoutTelephoneNavBar;">
      <app-user-loggedout-pc></app-user-loggedout-pc>
    </div>
    <!-- Telephone LoggedOut NewTemplate -->
    <ng-template #logoutTelephoneNavBar>
      <app-user-loggedout-telephone></app-user-loggedout-telephone>
    </ng-template>
  </ng-template>
</div>
