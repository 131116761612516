import {url} from '../../url/http-url-resources';

export class AdminHttpUrlResources {

  public static createClassRoomUrl(): string {
    const finalUrl = url + 'rest/classe/creaClasse';
    return finalUrl;
  }

  public static getAllClassRoomsUrl(): string {
    const finalUrl = url + 'rest/classe/listaClassiBase';
    return finalUrl;
  }

  public static getClassRoomFilteredByQuery(): string {
    const finalUrl = url + 'rest/classe/listaClassiBaseQuery';
    return finalUrl;
  }

  public static getClassRoomUrl(index: string): string {
    const finalUrl = url + 'rest/classe/getClasse/' + index;
    return finalUrl;
  }

  public static editClassRoomUrl() {
    const finalUrl = url + 'rest/classe/modificaClasse';
    return finalUrl;
  }

  public static createStundent(): string {
    const finalUrl = url + 'rest/utente/creaUtente';
    return finalUrl;
  }


  public static getAllStudent(): string {
    const finalUrl = url + 'rest/utente/lista';
    return finalUrl;
  }

  public static getStudent(index: string): string {
    const finalUrl = url + 'rest/utente/getStudente/' + index;
    return finalUrl;
  }

  public static getStudentFilteredByQuery(): string {
    const finalUrl = url + 'rest/utente/listaUtentiQuery';
    return finalUrl;
  }

  public static editStudent() {
    const finalUrl = url + 'rest/utente/modificaUtente';
    return finalUrl;
  }

  public static enableStudent(): string {
    const finalUrl = url + 'rest/classe/assegnaStudenteAllaClasse';
    return finalUrl;
  }

  public static disableStudent(): string {
    const finalUrl = url + 'rest/classe/rimuoviStudenteDallaClasse';
    return finalUrl;
  }


  public static getListaCertificazioni(): string {
    const finalUrl = url + 'rest/certificazione/listaCertificazioni';
    return finalUrl;
  }


  public static getCertificazione(id: string): string {
    const finalUrl = url + 'rest/certificazione/getCertificazione/' + id;
    return finalUrl;
  }

  public static getListaDomini(idCertificazione?: string): string {
    const finalUrl = url + 'rest/dominio/listaDomini/' + idCertificazione;
    return finalUrl;
  }

  public static getTaskForParticularDominio(): string {
    const finalUrl = url + 'rest/dominio/listaTaskDomini';
    return finalUrl;
  }

  public static getDomandeFiltrate(): string {
    const finalUrl = url + 'rest/domanda/listaDomande';
    return finalUrl;
  }

  public static getDomanda(id: number): string {
    const finalUrl = url + 'rest/domanda/getDomanda/' + id;
    return finalUrl;
  }

  public static getListaTipoTest(tipoTestId?: string): string {
    const finalUrl = url + 'rest/tipoTest/listaTipoTest/' + tipoTestId;
    return finalUrl;
  }

  public static getListaApproccio(tipoTestId?: string): string {
    const finalUrl = url + 'rest/approccio/listaApproccio/' + tipoTestId;
    return finalUrl;
  }


  public static getListaTest(idCertificazione?: number): string {
    let finalUrl;
    if (idCertificazione) {
      finalUrl = url + 'rest/test/listaTestXcertificazione/' + idCertificazione;
    } else {
      finalUrl = url + 'rest/test/lista';
    }
    // const finalUrl = url + 'rest/test/lista';
    return finalUrl;
  }

  public static getTest(id: number): string {
    const finalUrl = url + 'rest/test/getTest/' + id;
    return finalUrl;
  }

  public static getTestForClassroom(idTest: number, idClassroom: number): string {
    const finalUrl = url + 'rest/lezione/reportDocentePerTest/' + idTest + '/' + idClassroom;
    return finalUrl;
  }

  public static getListaTestFiltrata(): string {
    const finalUrl = url + 'rest/test/listaTest';
    return finalUrl;
  }

  public static assegnaTestAllaClasse(): string {
    const finalUrl = url + 'rest/classe/assegnaTestAllaClasse';
    return finalUrl;
  }

  public static rimuoviTestDallaClasse(): string {
    const finalUrl = url + 'rest/classe/rimuoviTestDallaClasse';
    return finalUrl;
  }

  public static eliminaImmagine(idDomanda: number): string {
    const finalUrl = url + 'rest/domanda/eliminaImgDomanda/' + idDomanda;
    return finalUrl;
  }

  public static salvaImmagine(): string {
    const finalUrl = url + 'rest/domanda/caricaImgDomanda';
    return finalUrl;
  }

  public static editQuestion() {
    const finalUrl = url + 'rest/domanda/modificaDomanda';
    return finalUrl;
  }

  public static editQuestionAnswer() {
    const finalUrl = url + 'rest/domanda/modificaRisposte';
    return finalUrl;
  }

  public static markLtAsFreeOrPayed() {
    return url + 'rest/test/setTestFreeLT';
  }

  public static chiudiClasse() {
    return url + 'rest/classe/chiudiClasse';
  }

  public static getTicket() {
    return url + 'rest/ticket/lista';
  }

  public static salvaTicket() {
    return url + 'rest/ticket/salvaTicket';
  }

  public static modificaTicket() {
    return url + 'rest/ticket/modificaTicket';
  }

  public static getReportData() {
    return url + 'rest/test/getReportTestStudenti';
  }

  //NewTemplate
  public static getListaTemplate() {
    return url + 'rest/classe/listaTemplateClassi';
  }

  public static creaTemplate() {
    return url + 'rest/classe/creaTemplateClasse';
  }

  public static modificaTemplate() {
    return url + 'rest/classe/modificaTemplateClasse';
  }

  public static assegnaTestAlTemplate() {
    return url + 'rest/classe/assegnaTestAlTemplateClasse';
  }

  public static rimuoviTestAlTemplate() {
    return url + 'rest/classe/rimuoviTestAlTemplateClasse'
  }

  public static getTemplate(id: number) {
    return url + 'rest/classe/getTemplate/' + id;
  }

  public static creaClasseDaTemplate(idTemplate: number) {
    return url + 'rest/classe/creaClasseDaTemplate/'+idTemplate;
  }

  public static enableStudentTemplate(){
    return url + 'rest/classe/assegnaStudenteAllaClasseDaTemplate';
  }

  public static modificaAssegnazioneStudenteClasseTemplate() {
    return url + 'rest/classe/modificaDateAssegnazioneStudenteAllaClasseDaTemplate';
  }

  public static listaTestCompletatiNelPeriodo(): string {
    return url + 'rest/test/listaTestCompletatiNelPeriodo';
  }

  public static listaTestCompletatiUtentiNelPeriodo(): string {
    return url + 'rest/test/listaTestCompletatiUtentiNelPeriodo';
  }

  public static listaEmailInviate(): string {
    return url + 'rest/utente/listaEmailInviate';
  }

  public static invioEmailUtenti(): string {
    return url + 'rest/utente/invioEmailUtenti';
  }

  public static listaEmailInviateByData(): string {
    return url + 'rest/utente/listaEmailInviateByData';
  }

  public static modificaDescrizioniTest(): string {
    return url + 'rest/test/modificaDescrizioniTest';
  }

  public static listaUtentiXreport(): string {
    return url + 'rest/utente/listaUtentiXreport';
  }
}
