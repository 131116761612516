import {createAction, props} from '@ngrx/store';
import {DialogData} from '../../../shared/model/dialog.data';
import {Profile} from '../../model/profile';
import {ProfiloFatturazione} from "../../model/profilo-fatturazione";

export const editPassword = createAction('[EditPasswordComponent] Edit Password',
  props<{ nuovaPassword: string }>()
);

export const editPasswordFail = createAction('[ProfileEffect] Fail To Edit Password',
  props<{ dialogData: DialogData }>()
);

export const editPasswordSuccess = createAction('[ProfileEffect] Succesfully Edit Password',
  props<{ dialogData: DialogData }>()
);

export const editProfileInfo = createAction('[ProfileInfoComponent] Edit Profile Info',
  props<{ profile: Profile }>()
);

export const editProfileInfoSuccess = createAction('[ProfileEffect] Successfully Edit Profile Info',
  props<{ dialogData: DialogData, profileInfo: Profile }>()
);

export const editProfileInfoFail = createAction('[ProfileEffect] Fail To Edit Profile Info',
  props<{ dialogData: DialogData }>()
);

export const getProfileInfo = createAction('[ProfileResolver] Get Profile Info From Server',
  props<{ accountId: number }>()
);

export const getProfileInfoSuccess = createAction('[ProfileEffect] Succesfully Get Profile Info',
  props<{ profile: Profile }>()
);

export const getProfileInfoFail = createAction('[ProfileEffect] Fail To Get Profile Info',
  props<{ dialogData }>()
);

export const setProfiloFatturazione = createAction('[ProfiloFatturazioneComponent] Set Profilo di Fatturazione',
  props<{ profiloDiFatturazione: ProfiloFatturazione }>());

export const setProfiloFatturazioneSuccess = createAction('[ProfiloFatturazioneEffect] Profilo di Fatturazione settato correttamente',
  props<{ profiloDiFatturazione: ProfiloFatturazione, dialogData: DialogData }>());

export const setProfiloFatturazioneFail = createAction('[ProfiloFatturazioneEffect] Errore nel settare il Profilo di Fatturazione',
  props<{ dialogData }>());

export const editProfiloFatturazione = createAction('[ProfiloFatturazioneComponent] Edit Profilo di Fatturazione',
  props<{ profiloDiFatturazione: ProfiloFatturazione }>());

