import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {JwtDecodedObject} from '../model/jwt.decoded.object';
import {User} from '../model/user';
import {AuthService} from '../store/services/auth.service';
import * as moment from 'moment-timezone';
import {AppState} from '../../reducers';
import {Store} from '@ngrx/store';
import {AuthActions} from '../store/actions/action-types';
import {jwtDecode} from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AlreadyLoginRedirectGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private store: Store<AppState>) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.authService.getToken();
    let decoded: JwtDecodedObject;
    if (!token) {
      return true;
    }
    try {
      decoded = jwtDecode(token);
    } catch (Exception) {
      this.authService.deleteToken();
      return true;
    }
    const expirationDate = new Date(decoded.exp * 1000);
    if (moment.tz(new Date(), 'Europe/London').isSameOrAfter(moment.tz(expirationDate, 'Europe/London'))) {
      this.authService.deleteToken();
      return true;
    }
    const userFromToken: User = {
      id: decoded.id,
      ruolo1: decoded.ruolo1,
      certificazione1: decoded.certificazione1,
      ruolo2: decoded.ruolo2,
      certificazione2: decoded.certificazione2,
      ruolo3: decoded.ruolo3,
      certificazione3: decoded.certificazione3,
      ruolo4: decoded.ruolo4,
      certificazione4: decoded.certificazione4,
      exp: decoded.exp,
      username: decoded.sub,
      ip: decoded.ip,
      userAgent: decoded.userAgent
    };
    if (userFromToken.ruolo1 === '1' && userFromToken.ruolo2 === '1' && userFromToken.ruolo3 === '1' && userFromToken.ruolo4 === '1') {
      this.router.navigateByUrl('/admin/index');
      this.store.dispatch(AuthActions.loginWithToken({user: userFromToken}));
      return false;
    } else if (userFromToken.ruolo1 === '2' || userFromToken.ruolo1 === '3' || userFromToken.ruolo1 === '4' ||
      userFromToken.ruolo2 === '2' || userFromToken.ruolo2 === '3' || userFromToken.ruolo2 === '4' ||
      userFromToken.ruolo3 === '2' || userFromToken.ruolo3 === '3' || userFromToken.ruolo3 === '4' ||
      userFromToken.ruolo4 === '2' || userFromToken.ruolo4 === '3' || userFromToken.ruolo4 === '4') {
      this.router.navigateByUrl('/user/index');
      return false;
    } else {
      return true;
    }
  }

}
