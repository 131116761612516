<div class="flexbox-wrapper">
  <div class="flex-item pt-2 pb-2 pl-2">
    <img class="pclogo" src="assets/logo.png" alt="logo PMTest">
  </div>
  <div class="flex-item pt-2 pb-2">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="navigateToPublicPages()" pButton pRipple
            type="button" label="HOME" icon="pi pi-home"
            class="p-button-secondary p-button-text p-1">
    </button>
  </div>
  <div class="flex-item pt-2 pb-2">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="navigate('index', $event)" pButton pRipple
            type="button" label="CERTIFICATIONS" icon="pi pi-clone"
            class="p-button-secondary p-button-text p-1">
    </button>
  </div>
  <div class="flex-item pt-2 pb-2">
    <div>
      <p-menu #dropdown [popup]="true" [model]="items" (click)="clicked($event)"></p-menu>
      <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton pRipple (click)="dropdown.toggle($event)"
              type="button" label="DASHBOARD" icon="pi pi-table"
              class="p-button-secondary p-button-text p-1">
      </button>
    </div>
  </div>
  <div class="flex-item pt-2 pb-2">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="navigate('profile')" pButton pRipple
            type="button" label="PROFILE" icon="pi pi-user"
            class="p-button-secondary p-button-text p-1">
    </button>
  </div>
  <div class="flex-item pt-2 pb-2">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton pRipple
            (click)="navigate('subscriptions')"
            type="button" label="SUBSCRIPTIONS" icon="pi pi-inbox"
            class="p-button-secondary p-button-text p-1">
    </button>
  </div>
  <div class="flex-item pt-2 pb-2">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="navigate('cart')" pButton pRipple
            type="button" label="CART" icon="pi pi-shopping-cart"
            class="p-button-secondary p-button-text p-1">
      <span class="badge badge-danger mr-2">{{itemInCart}}</span>
    </button>
  </div>
  <div class="flex-item pt-2 pb-2">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="navigate('digital-media')" pButton pRipple
            type="button" label="DIGITAL MEDIA" icon="pi pi-file"
            class="p-button-secondary p-button-text p-1">
    </button>
  </div>
  <div class="flex-item pt-2 pb-2">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="navigate('help')" pButton pRipple
            type="button" label="HELP" icon="pi pi-question-circle"
            class="p-button-secondary p-button-text p-1">
    </button>
  </div>
  <div class="flex-item pt-2 pb-2">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="openCloseSideBar()" pButton
            pRipple type="button" label="MENU" icon="pi pi-bars"
            class="p-button-secondary p-button-text p-1"></button>
    <img class="cert-img ml-2" *ngIf="selectedCertification" [src]="'assets/img/'+selectedCertification?.tipo +'.PNG'" alt="logo img">
  </div>
  <div class="flex-item pt-2 pb-2" *ngIf="!this.router.url.includes('test/question') && !this.router.url.includes('user/learningtest')">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="logout()" pButton pRipple
            type="button" label="" icon="pi pi-sign-out"
            class="p-button-secondary p-button-text p-1"></button>
  </div>
</div>
