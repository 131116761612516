import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './auth/components/login/login.component';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {AdminGuard} from './auth/guards/admin.guard';
import {AlreadyLoginRedirectGuard} from './auth/guards/already-login-redirect.guard';
import {UserGuard} from './auth/guards/user.guard';
import {ForgotPasswordComponent} from './welcome/components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './welcome/components/reset-password/reset-password.component';
import {SignupComponent} from './welcome/components/signup/signup.component';
import {IndexPageComponent} from './welcome/components/index-page/index-page.component';
import {HelpComponent} from "./welcome/components/help/help.component";
import {DigitalMediaComponent} from "./welcome/components/digital-media/digital-media.component";


const routes: Routes = [
  {
    path: '',
    // component: IndexPageComponent
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'help',
    component: HelpComponent
  },
  // {
  //   path: 'digital-media',
  //   component: DigitalMediaComponent
  // },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AlreadyLoginRedirectGuard]
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'newpassword/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [UserGuard],
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
