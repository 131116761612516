<div class="flexbox-wrapper">
  <div class="flex-item pt-3 pb-2 pl-3 pr-3">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="navigate('/admin/index')" pButton pRipple
            type="button" icon="pi pi-home"
            class="p-button-secondary p-button-text">
    </button>
  </div>
  <div class="flex-item pt-3 pb-2 pl-3 pr-3">
    <p-menu #classroommenu [popup]="true" [model]="classRoomItems"></p-menu>
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton pRipple
            type="button" label="CLASSROOM" icon="pi pi-users" (click)="classroommenu.toggle($event)"
            class="p-button-secondary p-button-text">
    </button>
  </div>
  <div class="flex-item pt-3 pb-2 pl-3 pr-3">
    <p-menu #studentmenu [popup]="true" [model]="studentItems"></p-menu>
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="studentmenu.toggle($event)" pButton pRipple
            type="button" label="STUDENT" icon="pi pi-user"
            class="p-button-secondary p-button-text">
    </button>
  </div>
  <div class="flex-item pt-3 pb-2 pl-3 pr-3">
    <p-menu #testmenu [popup]="true" [model]="testItems"></p-menu>
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton pRipple
            type="button" label="TEST" icon="pi pi-file-o" (click)="testmenu.toggle($event)"
            class="p-button-secondary p-button-text">
    </button>
  </div>
  <div class="flex-item pt-3 pb-2 pl-3 pr-3">
    <p-menu #questionmenu [popup]="true" [model]="questionItems"></p-menu>
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton pRipple
            type="button" label="QUESTION" icon="pi pi-question-circle" (click)="questionmenu.toggle($event)"
            class="p-button-secondary p-button-text">
    </button>
  </div>
  <div class="flex-item pt-3 pb-2 pl-3 pr-3">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton pRipple
            type="button" label="TICKET" icon="pi pi-bell" (click)="navigate('/admin/ticket')"
            class="p-button-secondary p-button-text pr-2">
    </button>
    <div class="d-inline-flex position-absolute align-items-center">
      <span class="badge badge-primary">
        {{ticketBadge}}
      </span>
    </div>
  </div>
  <div class="flex-item pt-3 pb-2 pl-3 pr-3">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="navigate('/admin/comunicazioni')" pButton pRipple
            type="button" label="SERVICE ALERTS" icon="pi pi-comments"
            class="p-button-secondary p-button-text">
    </button>
  </div>
  <div class="flex-item pt-3 pb-2 pl-3 pr-3">
    <p-menu #reportmenu [popup]="true" [model]="reportItems"></p-menu>
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" pButton pRipple
            type="button" label="REPORT" icon="pi pi-file-excel" (click)="reportmenu.toggle($event)"
            class="p-button-secondary p-button-text">
    </button>
  </div>
  <div class="flex-item pt-3 pb-2 pl-3 pr-3">
<!--    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="openCloseSideBar()" pButton-->
<!--            pRipple type="button" label="MENU" icon="pi pi-bars"-->
<!--            class="p-button-secondary p-button-text"></button>-->
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="logout()" pButton pRipple
            type="button" label="LOGOUT" icon="pi pi-sign-out"
            class="p-button-secondary p-button-text"></button>
  </div>
</div>
