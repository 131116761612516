import {Component, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from '../../../shared/store/service/shared.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.css']
})
export class IndexPageComponent implements OnInit, OnDestroy {
  prereqShow = {
    pmp: false,
    pmi: false,
    capm: false
  };

  mainShow = {
    questions: {
      language: false,
      comment: false,
      questionOrigin: false,
      questionNumbers: false
    },
    tests: {
      lt: false,
      me: false,
      sme: false,
      repeat: false
    },
    report: {
      errata: false,
      answers: false
    }
  };

  scrollSub: Subscription;

  constructor(private shared: SharedService) {
  }

  ngOnInit(): void {
    this.scrollSub = this.shared.pageScroll.subscribe(fragment => {
      document.querySelector('#' + fragment).scrollIntoView({behavior: 'smooth'});
    });
  }

  show(cert: string) {
    this.prereqShow[cert] = !this.prereqShow[cert];
  }

  showMain(group: string, sub: string): void {
    this.mainShow[group][sub] = !this.mainShow[group][sub];
  }

  ngOnDestroy() {
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }
  }

}
