import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {User} from '../../../../auth/model/user';
import {selectUser} from '../../../../auth/store/selectors/auth.selector';
import {AuthActions} from '../../../../auth/store/actions/action-types';
import {WelcomeAction} from '../../../store/actions/welcome.action.types';

@Component({
  selector: 'app-sidebar-user-loggedin-telephone',
  templateUrl: './sidebar-user-loggedin-telephone.component.html',
  styleUrls: ['./sidebar-user-loggedin-telephone.component.css']
})
export class SidebarUserLoggedinTelephoneComponent implements OnInit {

  user$: Observable<User>;

  constructor(private store: Store<AppState>, private router: Router) {
  }

  ngOnInit(): void {
    this.user$ = this.store.select(selectUser);
  }

  navigate(destination: string) {
    const url = 'user/' + destination;
    this.router.navigateByUrl(url);
    this.store.dispatch(WelcomeAction.closeSideBar());
  }


  navigateToReportList() {
    this.router.navigateByUrl('user/reportlist');
    this.store.dispatch(WelcomeAction.closeSideBar());
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
    this.store.dispatch(WelcomeAction.closeSideBar());
  }

}
