import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {WelcomeAction} from '../../../store/actions/welcome.action.types';
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-loggedout-telephone',
  templateUrl: './user-loggedout-telephone.component.html',
  styleUrls: ['./user-loggedout-telephone.component.css']
})
export class UserLoggedoutTelephoneComponent implements OnInit {

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
  }

  openCloseSideBar() {
    this.store.dispatch(WelcomeAction.openCloseSideBar());
  }

  navigate(destination: string): void {
    this.router.navigateByUrl(destination);
  }

}
