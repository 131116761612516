import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Signup} from '../../model/signup';
import {WelcomeAction} from '../../store/actions/welcome.action.types';
import {AppState} from '../../../reducers';
import {Store} from '@ngrx/store';
import {selectAllTimezone} from '../../../shared/store/selectors/timezone.selector';
import {Observable} from 'rxjs';
import {Timezone} from '../../../shared/model/timezone';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  SITE_KEY = '6Ld6m34bAAAAAKS8wN7-v3Bs9BgWsRQfX06Bwpw0'; //prod server
  // SITE_KEY = '6LcUHCQaAAAAAIu3-S5R74_NI5aINwTauMVNweDF'; //test server
  // SITE_KEY = '6Lf5GyQaAAAAALwwNmzeaEXo4GQXgGmFrZaoMxgL';// dev server
  captchaResponse;

  zone$: Observable<Timezone[]>;
  errorOnTelephone = false;

  studentForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    nome: new FormControl('', [Validators.required, Validators.minLength(1), this.trimValidator]),
    cognome: new FormControl('', [Validators.required, Validators.minLength(1), this.trimValidator]),
    azienda: new FormControl(''),
    telefono: new FormControl(''),
    prefix: new FormControl(''),
    nazione: new FormControl('', [Validators.minLength(2), Validators.required])
  });

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.zone$ = this.store.select(selectAllTimezone);
  }

  public get email() {
    return this.studentForm.get('email');
  }


  public get azienda() {
    return this.studentForm.get('azienda');
  }

  public get nome() {
    return this.studentForm.get('nome');
  }

  public get cognome() {
    return this.studentForm.get('cognome');
  }

  public get telefono() {
    return this.studentForm.get('telefono');
  }

  public get prefix() {
    return this.studentForm.get('prefix');
  }

  public get nazione() {
    return this.studentForm.get('nazione');
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

  saveStudent() {
    this.errorOnTelephone = false;
    if (!this.checkPhone()) {
      this.errorOnTelephone = true;
    }
    if (!this.errorOnTelephone) {
      let plus = '';
      if (this.telefono.value.toString().length > 1
        && this.prefix.value.toString().length > 1) {
        plus = '+';
      }
      const student: Signup = {
        email: this.email.value,
        nome: this.nome.value.toString().trim(),
        cognome: this.cognome.value.toString().trim(),
        azienda: this.azienda.value.toString().trim(),
        descrizione: null,
        telefono: plus + this.prefix.value.toString().trim() + this.telefono.value.toString().trim(),
        zona: this.nazione.value
      };
      if (!this.captchaResponse || !this.studentForm.valid) {
        return;
      }
      this.store.dispatch(WelcomeAction.registration({signup: student}));
    }

  }

  private trimValidator(control: FormControl) {
    return (control.value !== undefined && control.value !== null && control.value.trim() === '') ? {required: true} : null;
  }


  private checkPhone(): boolean {
    if (this.telefono.value.toString().length > 1
      && this.prefix.value.toString().length > 1
    ) {
      return true;
    } else if (this.telefono.value.toString().length === 0 &&
      this.prefix.value.toString().length === 0
    ) {
      return true;
    } else {
      return false;
    }
  }
}
