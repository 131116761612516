import {Component, OnInit} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {AppState} from '../../../reducers';
import {Store} from '@ngrx/store';
import {isLoggedIn, selectUser} from '../../../auth/store/selectors/auth.selector';
import {Observable} from 'rxjs';
import {selectIsSideBarOpen, selectNavbarVisibility} from '../../store/selectors/welcome.selectors';
import {WelcomeAction} from '../../store/actions/welcome.action.types';
import {Router} from '@angular/router';
import {User} from '../../../auth/model/user';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  isNavbarOpen$: Observable<boolean>;
  isTabletOrLarger$ = this.breakpointObserver.observe(['(min-width: 1135px)']);
  isLoggedIn$: Observable<boolean>;
  isSideBarOpen$: Observable<boolean>;
  user$: Observable<User>;

  constructor(private breakpointObserver: BreakpointObserver, private store: Store<AppState>, private router: Router) {
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.store.select(isLoggedIn);
    this.isSideBarOpen$ = this.store.select(selectIsSideBarOpen);
    this.user$ = this.store.select(selectUser);
    this.isNavbarOpen$ = this.store.select(selectNavbarVisibility);
  }

  closeSideBar() {
    this.store.dispatch(WelcomeAction.closeSideBar());
  }
}
