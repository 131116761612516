export function getErrorMessage(lang: string, from: string): string {
  switch (lang) {
    default:
      switch (from) {
        case 'getTestFilter':
          return 'Could not load filtered test';
        case 'getTestList':
          return 'Could not load test';
        case 'getSingleTest':
          return 'Could not load test data';
        case 'getSingleTestForClassroom':
          return 'Could not load test for classroom data';
        case 'assignTestToClassroom':
          return 'Fail to assign test';
        case 'removeTestFromClass':
          return 'Fail to remove test from classroom';
        case 'markLtAsFreeOrPayed':
          return 'Fail to edit LT status';
        case 'loadCertificationType':
          return 'Could not load certification type you will move to index page';
        case 'createClassroom':
          return 'Could not create classroom';
        case 'getAllClassroom':
          return 'Fail to load all classrooms';
        case 'getClassroom':
          return 'Can not get classroom detail';
        case 'editClassroom':
          return 'Could not edit the classroom';
        case 'closeClassroom':
          return 'Could not close Class';
        case 'loadCertificationTypeData':
          return 'Fail To Certification Data please check your internet connection';
        case 'getQuestion':
          return 'There are no questions that respect the parameters entered';
        case 'getSingleQuestion':
          return 'Fail to load question data';
        case 'deleteImage':
          return 'Fail to delete image';
        case 'saveImage':
          return 'Fail to load image';
        case 'editQuestion':
          return 'Fail to edit question';
        case 'getStudent':
          return 'Could not load the Student';
        case 'editStudent':
          return 'Fail to edit the Student';
        case 'enableStudent':
          return 'Could not assign the student to that class';
        case 'disableStudent':
          return 'Could not remove the student from that class';
        case 'loadTask':
          return 'Could not load task';
        case 'logout':
          return 'Error in logout';
        case 'loginFailForInternetConnection':
          return 'Please check your internet connection';
        case 'sendEmailForResetPassword':
          return 'User Not Found';
        case 'resetPassword':
          return 'Can not reset password';
        case 'registration':
          return 'Fail to signup';
        case 'getLt':
        case 'getSme':
        case 'getMe':
        case 'getListaGruppiLt':
          // return 'Fail to get learning test';
          return 'There are no test avaible at the moment. Please try later.';
        case 'finishLtAndSendData':
          return 'Fail to save learning test outcome';
        case 'editPassword':
          return 'Fail to edit password';
        case 'getProfileInfo':
          return 'Could not load profile info';
        case 'editProfile':
          return 'Fail to edit profile info';
        case 'getReportList':
          return 'Can not load report list';
        case 'getTestResult':
          return 'Can not load report';
        case 'getTest':
          return 'Fail to get test';
        case 'getCertificazioni':
          return 'Fail to load certification';
        case 'getCertificazioniWithSubData':
          return 'Fail to get certification please reload the page';
        case 'getAllStudent':
          return 'Could not load the Students';
        case 'getClassroom':
          return 'Could not load classrooms';
        case 'getStudentListaTipoTest':
          return 'Could not load test list type';
        // case 'getSme':
        //   return 'SME not found for this class';
        // case 'getMe':
        //   return 'ME not found for this class';
        // case 'getListaGruppiLt':
        //   return 'Fail to load Lt group';
        // case 'getLt':
        //   return 'No LT for this class';
        case 'getListaGruppiLtRefreshed':
          return 'Fail to refresh data, please retry';
        case 'getSmeOrMeSenzaClasse':
          return 'Fail to refresh data, please retry';
        case 'getListaGruppiLtPerCertificazione':
          return 'Could not load Lt label';
        // case 'user.getLt':
        //   return 'Fail to load Lt list';
        case 'setProfiloFatturazione':
          return 'It was not possible to add and / or modify your billing profile. Try again!';
        case 'modificaTicket':
        case 'salvaTicket':
          return 'Something goes wrong, please try again';
        case 'getTemplates':
          return 'Could not load templates';
        default: return null;
      }
  }
}
