import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-media',
  templateUrl: './digital-media.component.html',
  styleUrls: ['./digital-media.component.css']
})
export class DigitalMediaComponent implements OnInit {

  selectedLang = 'ENG';

  digitalMedia: DigitalMedia[] = [
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'video',
      competenceArea: CompetanceArea.WAYS_OF_WORKING,
      descriptionIta: 'Il video compara il metodo dell\'Earned Value con quello dell\'Earned Schedule, entrambi utili per la valutazione della performance di progetto. Oltre a presentare un\'esaustiva spiegazione delle formule necessarie, si analizzano i differenti metodi in esempi contestualizzati di attività di pianificazione.',
      descriptionEng: 'The video compares the Earned Value method with the Earned Schedule method, both useful to the evaluation of project performance. In addition to an exhaustive explanation of the necessary formulas, the two different methods are analysed in contextualised planning activities examples.',
      pdu: 2,
      title: '01 - Earned Schedule',
      language: 'ITA'
    },
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'video',
      competenceArea: CompetanceArea.WAYS_OF_WORKING,
      descriptionIta: 'Il video offre una panoramica del risk management all\'interno del flowchart dei processi del PMBOK. Viene descritta la tecnica PERT e i suoi modelli matematici. PERT viene poi utilizzata per l’analisi dei rischi nella gestione di un progetto di servizi; l’esempio viene sviluppato anche attraverso l’uso degli strumenti matematici offerti dall\'uso di un foglio di calcolo MS Excel.',
      descriptionEng: 'The video provides an overview of Risk Management within the PMBOK process flowchart. The PERT technique and its mathematical models are described. PERT is then used for risk analysis in the management of a service project; the example is carried out through the use of the mathematical tools of a MS Excel spreadsheet.',
      pdu: 2,
      title: '02 - Risk management: case study',
      language: 'ITA'
    },
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'video',
      competenceArea: CompetanceArea.POWER_SKILLS,
      descriptionIta: 'Il video illustra inizialmente il concetto di ruolo all\'interno di dimensioni organizzative e gruppi di lavoro e ne analizza le caratteristiche e le competenze. Viene poi ampiamente spiegato il concetto di leadership, a partire dall\'applicazione di questo concetto a una visione moderna della guida e della gestione dei gruppi di lavoro e di progetto. Viene infine analizzato il modello di Leadership Situazionale attraverso i concetti di orientamento, maturità dei collaboratori e stili di leadership contestuali.',
      descriptionEng: 'The video illustrates the concept of role within organizations and working groups and analyzes its characteristics and skills. Then, the concept of leadership is widely explained, starting from the application of this concept to a modern vision of the guidance and management of work and project groups. Lastly, the Situational Leadership Model is analyzed through the concepts of orientation, employee maturity and contextual leadership styles.',
      pdu: 2,
      title: '03 - Leadership situazionale',
      language: 'ITA'
    },
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'video',
      competenceArea: CompetanceArea.BUSINESS_ACUMEN,
      descriptionIta: 'Il video offre una panoramica sugli aspetti fondanti dello Strategic Management. Vengono descritti alcuni problemi e le relative cause che portano, a volte, a concludere i progetti e a non apprezzarne i risultati rispetto alle strategie e al business dell’azienda che lo ha avviato. Il video espone anche due modelli che aiutano a determinare quali indicatori siano utili da monitorare durante il progetto per garantire che vi sia sempre un corretto allineamento tra business/strategia dell’azienda e il progetto.',
      descriptionEng: 'The video provides an overview of the fundamental aspects of Strategic Management. A few problems and their causes, that sometimes lead to not appreciating the results at the completion of projects with respect to the strategies and business of the company that started them, are presented. The video also exposes two models that help determine which indicators are supposed to be monitored during the project to ensure that there is always a correct alignment between the company\'s business/strategy and the project.',
      pdu: 2,
      title: '04 - Strategic management fundamentals',
      language: 'ITA'
    },
    {
      author: {
        name: 'Stefano Orfei',
        link: 'https://www.linkedin.com/in/stefano-orfei/'
      },
      type: 'video',
      competenceArea: CompetanceArea.WAYS_OF_WORKING,
      descriptionIta: 'Il video introduce il tempo come concetto della quotidianità e dell’esperienza comune e quindi lo inserisce nel concetto dell’organizzazione sia personale sia del lavoro di gruppo. Vengono descritti i fattori e le leggi che definiscono la gestione del tempo nel contesto del project management con una particolare attenzione ai concetti di obiettivo, urgenza, priorità e pianificazione.',
      descriptionEng: 'The video introduces time as a concept of everyday life and common experience, and then deals with it with reference to the fields of personal organization and group work. It describes the factors and laws that define time management in the context of project management with particular focus on the concepts of objective, urgency, priority and planning.',
      pdu: 2,
      title: '05 - Gestione del tempo',
      language: 'ITA'
    },
    {
      author: {
        name: 'Fabio Macchi',
        link: 'https://www.linkedin.com/in/fabio-macchi-408a5b13/'
      },
      type: 'video',
      competenceArea: CompetanceArea.BUSINESS_ACUMEN,
      descriptionIta: 'La prima parte di questo video mostra una panoramica della complessità del mercato B2B, inclusa l\'analisi delle dinamiche di vendita e la revisione di un modello di comportamento di vendita efficace. Spiega anche la Piramide dei Valori, con particolare attenzione alla co-creazione del valore. Infine, spiega le buone pratiche per implementare con successo una strategia di differenziazione per una crescita redditizia e sostenibile, con l\'analisi dei pro e dei contro di tre diverse Proposte di valore.',
      descriptionEng: 'The first part of this video shows an overview of the B2B market complexity, inclusive of sales dynamics analysis, and the review of an effective Sales Behavior Model. It also explains Value Pyramid with focus on Value co-creation. Lastly, it explains good practices to successfully implement a differentiation strategy for a profitable and sustainable growth, with pros/cons analysis of three different Value Propositions.',
      pdu: 2,
      title: '06 - Offering design: the golden value proposition',
      language: 'ENG'
    },
    {
      author: {
        name: 'Fabio Macchi',
        link: 'https://www.linkedin.com/in/fabio-macchi-408a5b13/'
      },
      type: 'video',
      competenceArea: CompetanceArea.BUSINESS_ACUMEN,
      descriptionIta: 'La prima parte di questo video mette in evidenza i pro e i contro degli approcci ai prezzi orientati ai costi e degli approcci ai prezzi orientati ai costi ai clienti per gli acquisti B2B, con una revisione approfondita dell\'analisi del valore economico, lo strumento chiave per valutare l\'incentivo all\'acquisto. Quindi mostra diversi tipi di comportamento dell\'acquirente, nonché i pilastri di una strategia efficace per la determinazione del valore per una crescita sostenibile e redditizia: a) il processo di creazione del valore basato sulle determinanti del valore del cliente e sulla stima del valore netto di differenziazione; b) l\'allineamento della struttura dei prezzi con le reali capacità di determinazione dei prezzi dell\'azienda. Infine, vengono discusse le tre tecniche di determinazione dei prezzi principali per evitare l\'insidia del one-size-fits-all per diversi segmenti di valore di mercato.',
      descriptionEng: 'The first part of this video highlights pros & cons of cost-driven and customer-driven pricing approaches for the B2B purchases, with in depth review of the economic value analysis, the key tool to evaluate the inducement to purchase. Then, it shows different types of buyer behavior, and ultimately the pillars of a successful value pricing strategy for a sustainable and profitable growth: a) the value creation process based on the customer value drivers and estimation of net differentiation value; b) the alignment of the price structure with the real company pricing capabilities. Finally, the three core pricing techniques are discussed to avoid the one-size-fits-all pitfall for different market value segments.',
      pdu: 2,
      title: '07 - Offering design: guidelines for value based pricing',
      language: 'ENG'
    },
    {
      author: {
        name: 'Cristian Riva',
        link: 'https://www.linkedin.com/in/cristian-riva-pmp-mba-b01394b/'
      },
      type: 'article',
      competenceArea: CompetanceArea.BUSINESS_ACUMEN,
      descriptionIta: 'L\'articolo mostra un\'indagine sui cambiamenti del ruolo del project manager in Italia, come percepito dal mercato del lavoro, tra il 2013 e il 2016. L\'articolo identifica le competenze e le responsabilità richieste al project manager in diversi posti di lavoro e quindi le analizza statisticamente. Il risultato è una interessante descrizione del project manager ideale e del suo ruolo nel settore.',
      descriptionEng: 'The article shows an investigation of the changes in the role of project manager in Italy, as perceived by the job market, between 2013 and 2016. The article identifies the competences and the responsibilities requested to the project manager in several job places and then statistically analyzes them. The result is an interesting picture of the ideal project manager and their role in the industry.',
      pdu: 2,
      title: '08 - The evolution of project management in Italy viewed through job posts',
      language: 'ENG'
    },
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'article',
      competenceArea: CompetanceArea.BUSINESS_ACUMEN,
      descriptionIta: 'Il Project Portfolio Management è uno strumento efficace per la gestione strategica dell\'organizzazione. La sua popolarità sta crescendo insieme all\'aumento del numero e della capacità delle organizzazioni orientate al progetto e al livello di competenza del management. In diversi paesi, Project Portfolio Management è un approccio nuovo, ancora poco diffuso e non sufficientemente studiato in termini pratici. La sua promozione e l\'applicazione attiva nella pratica richiedono la formazione intensiva di una nuova generazione di manager nella gestione strategica delle aziende.',
      descriptionEng: 'Project Portfolio Management is an effective tool for strategic management of the organization. Its popularity is growing along with the increasing in the number and capacity of project-oriented organizations and the level of competence of management. In several countries, Project Portfolio Management is a new approach, not yet widespread and not sufficiently studied in practical terms. Its promotion and active application into practice require the intensive training of a new generation of managers in the strategic management of companies.',
      pdu: 2,
      title: '09 - Project Portfolio Management and Strategic Management',
      language: 'ITA'
    },
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'article',
      competenceArea: CompetanceArea.BUSINESS_ACUMEN,
      descriptionIta: 'Al giorno d\'oggi, la maggior parte dei dirigenti senior può descrivere con sicurezza la strategia organizzativa. Sono anche in grado di raccontare in poche parole come la strategia è cambiata nel tempo. Tuttavia, se chiesto loro come vengono prese le decisioni su come cambiare quella strategia, molti hanno problemi a rispondere. Il fatto è che molti dirigenti senior hanno difficoltà a descrivere come vengono prese le decisioni strategiche. Questo è un problema serio, poiché il processo in base alla quale vengono prese le decisioni strategiche è in grado di modellare la strategia stessa. Impostare una strategia senza conoscere il proprio processo è come navigare senza bussola.',
      descriptionEng: 'Nowadays, most senior executives can confidently describe organizational strategy. They can also rapidly tell how the strategy has changed over time. However, if you ask them how they make decisions about changing that strategy, many of them face difficulties answering. The fact is that many senior executives struggle to describe how they make strategic decisions. That is a serious problem, since the process for making strategic decisions can shape the strategy itself. Making a strategy without knowing your process is like sailing without a compass.',
      pdu: 2,
      title: '10 - Different Approaches to Set Strategy',
      language: 'ITA'
    },
    {
      author: {
        name: 'Alessandra Antonini',
        link: 'https://www.linkedin.com/in/alessandra-antonini-36148a25/'
      },
      type: 'article',
      competenceArea: CompetanceArea.POWER_SKILLS,
      descriptionIta: 'Il Project Manager dirige un team di risorse per ottenere il raggiungimento dell’obiettivo di progetto, coerentemente con le strategie aziendali dell’organizzazione a cui appartiene. Nel lavoro del Project Manager hanno sempre di più rilevanza le capacità personali, chiamate “soft skills”, che sono caratteristiche non propriamente legate alla competenza e alla conoscenza della materia del progetto e della sua gestione, ma racchiudono quelle capacità che trasformano il Project Manager in un vero e proprio Project Leader.',
      descriptionEng: 'The Project Manager leads a team of resources to achieve the project objective, consistent with the business strategies of the organization to which he belongs. In the work of the Project Manager, personal skills, or "soft skills", are increasingly relevant: these characteristics are not strictly related to the competence and knowledge of the field of the project and its management, but encompass those skills that transform the Project Manager into a real Project Leader.',
      pdu: 2,
      title: '11 - Ruolo del Project Manager nel gruppo di progetto',
      language: 'ITA'
    },
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'video',
      competenceArea: CompetanceArea.WAYS_OF_WORKING,
      descriptionIta: 'Il video illustra le principali funzionalità di MS Project per pianificare correttamente un progetto. Nello specifico, lo speaker illustra come mostrare la riga del progetto, stabilire la data d’inizio dell’intero progetto, organizzare la WBS, inserire le milestone e stabilire le scadenze, creare il reticolo di progetto. Nel corso del video viene spiegata anche l’assegnazione delle risorse (lavoro) e la formula di pianificazione che consente di stabilire le attività a durata, risorse o lavoro fisso. Si conclude poi con la spiegazione sul livellamento delle risorse e il salvataggio della baseline.',
      descriptionEng: 'The video explains the main features of MS Project to plan a project correctly. Specifically, the speaker explains how to show the project line, to establish the start date of the entire project, to organize the WBS, to insert milestones and to set deadlines, and to create the project network. The video also explains the allocation of resources (work) and the planning formula that allows you to establish tasks when the duration, the resources or the work are fixed. It ends with the explanation of Resource Leveling and the saving of the baseline.',
      pdu: 2,
      title: '12 - Ms Project - Pianificare un progetto',
      language: 'ITA'
    },
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'video',
      competenceArea: CompetanceArea.WAYS_OF_WORKING,
      descriptionIta: 'Il video tratta di vari aspetti attinenti alla personalizzazione dell’ambiente di MS Project. Lo speaker illustra come assegnare e gestire le risorse Materiali e Costi, come usare i Raggruppamenti, personalizzare il diagramma di Gantt e il reticolo. Inoltre, viene spiegato come personalizzare il Calendario e i Giorni lavorativi. Per concludere, vengono spiegati i campi personalizzati per determinare dei calcoli specifici o come strutturare dei campi con delle tendine da cui scegliere dei valori per catalogare le risorse o le attività.',
      descriptionEng: 'The video deals with various aspects related to the customization of the MS Project environment. The speaker explains how to assign and manage Material resources and Costs, how to use Groupings, to customize the Gantt Chart, and the network. He also explains how to customize your Calendar and Working Days. Finally, he explains the custom fields to determine specific calculations or how to structure fields with drop-down menus with values to select to catalog resources or tasks.',
      pdu: 2,
      title: '13 - Ms Project - Personalizzazioni',
      language: 'ITA'
    },
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'video',
      competenceArea: CompetanceArea.POWER_SKILLS,
      descriptionIta: 'Si sente sempre più spesso parlare di leadership, ma in cosa consiste? Il termine fu coniato nel 1970 e sono ovviamente soprattutto i progetti Agile ad impiegarla. I vantaggi di questo approccio sono molteplici, si va da una maggiore spirito di collaborazione fra i componenti del team, a migliori risultati di progetto, a un più alto morale e in generale ad una maggiore crescita di tutte le parti coinvolte dal progetto. A fronte di questi e altri benefici, occorre però sottolineare che il passaggio a una mentalità "servant" è un viaggio spesso lungo e molto impegnativo. Il video contribuirà a comprendere le caratteristiche della servant leadership per poterla attuare nel project management.',
      descriptionEng: 'We hear more and more about this leadership, but what does it consist of? The term was coined in 1970 and is obviously mainly used in Agile projects. The advantages of this approach are many, ranging from a greater spirit of collaboration among team members, to better project results, to higher morale and in general to greater growth of all parties involved in the project. In the face of these and other benefits, however, it should be emphasized that the transition to a "servant" mentality is often a long and very demanding journey. The video will help to understand the characteristics of servant leadership in order to implement it in project management.',
      pdu: 2,
      title: '14 - Agile Leadership',
      language: 'ITA'
    },
    {
      author: {
        name: 'Antonio Marino',
        link: 'https://www.linkedin.com/in/antoniomarino-pm/'
      },
      type: 'video',
      competenceArea: CompetanceArea.POWER_SKILLS,
      descriptionIta: 'Il video illustra inizialmente la tematica della leadership personale, affrontando il concetto del paradigma della doppia creazione per poi spiegare nello specifico le differenze tra le figure di leader e manager. Nel corso del video viene poi spiegata la gestione del tempo da parte del leader e il concetto della delega. Infine, vengono commentate alcune tra le più comuni cause di ritardi quando si gestisce un progetto ma anche come cercare migliorarne le performance.',
      descriptionEng: 'The video presents the theme of personal leadership, addressing the concept of the co-creation paradigm and then explaining the differences between the figures of leader and manager in detail. During the video, the leader\'s time management and the concept of delegation are explained. Lastly, some of the most common causes of delays when managing a project are presented, and also how to try to improve its performance.',
      pdu: 2,
      title: '15 - Leadership e Time Management',
      language: 'ITA'
    },
    {
      author: {
        name: 'Luca Pierri',
        link: 'https://www.linkedin.com/in/lucapierri/',
      },
      type: 'article',
      competenceArea: CompetanceArea.WAYS_OF_WORKING,
      descriptionIta: 'Alla base di qualunque sviluppo Agile e, in particolare, in quelli gestiti con il metodo Scrum c’è un Product Backlog ben costruito e gestito. Il Product Backlog consiste nella lista delle funzionalità da realizzare, ogni funzionalità è descritta dalle sue specifiche e priorità di realizzazione che possono essere riviste e modificate in ogni momento. Se a prima vista la gestione di un elenco delle funzionalità non sembra un lavoro particolarmente complicato ed oneroso, entrando nel vivo della questione viene fuori, invece, che un Backlog “pulito e parlante” è tutt\'altro che facile da creare, mantenere ed evolvere.',
      descriptionEng: 'At the base of any Agile development and, in particular, in those managed with the Scrum method, there is a well-constructed and managed Product Backlog. The Product Backlog consists of the list of features to be realized; each feature is described by its specifications and implementation priorities that can be reviewed and modified at any time. If, at first glance, the management of a list of features does not seem a particularly complicated and onerous job, when getting to the heart of the matter it is clear that a “clean and talking” Backlog is far from easy to create, maintain and evolve.',
      pdu: 2,
      title: '16 - Product Backlog',
      language: 'ITA'
    }

  ]

  constructor() { }

  ngOnInit(): void {
  }

  changeLang(): void {
    if(this.selectedLang == 'ITA'){
      this.selectedLang = 'ENG';
    } else {
      this.selectedLang = 'ITA';
    }
  }

}

interface DigitalMedia {
  competenceArea: string,
  title: string,
  pdu: number,
  author: {
    name: string,
    link: string
  },
  type: string,
  language: string,
  descriptionIta: string,
  descriptionEng: string
}

enum CompetanceArea {
  WAYS_OF_WORKING = 'Ways of Working',
  POWER_SKILLS = 'Power Skills',
  BUSINESS_ACUMEN = 'Business Acumen'
}
