<div class="position-absolute custom-position">
  <div class="row no-gutters justify-content-center">
    <div class="col-11 col-md-6">
      <div class="card  bg-light p-2 p-md-4">
        <h4 class="ml-1 mr-1 ml-md-4 mr-md-4 mt-2">LOGIN</h4>
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="ml-1 mr-1 ml-md-4 mr-md-4 mt-4">
            <label class="sr-only" for="username">Email</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text" [ngClass]="{'border-danger': username.invalid && username.touched}">
                  <i class="pi pi-envelope"></i>
                </div>
              </div>
              <input type="email" class="form-control" id="username" [ngClass]="{'border-danger': username.invalid && username.touched}" placeholder="john.smith@gmail.com" name="email" formControlName="username">
            </div>
          </div>
          <div *ngIf="username.invalid && username.touched" class="text-danger pl-1 ml-1 mr-1 ml-md-4 mr-md-4 mb-0 mt-2">
            Invalid Username
          </div>
          <div class="ml-1 mr-1 ml-md-4 mr-md-4 mt-4">
            <label class="sr-only" for="password">Password</label>
            <div class="input-group">
              <input [type]="(showPassword$ | async)?'text':'password'" autocomplete="on" class="form-control bg-white" id="password" placeholder="password" name="password" formControlName="password">
              <div class="input-group-prepend" (click)="showPassword()">
                <div class="input-group-text">
                  <i class="pi pi-eye"></i>
                </div>
              </div>
            </div>
            <div class="text-right">
              <small class="pl-2">
                <a id="forgotpassword" (click)="navigateToForgotPassword()" class="forgot">Forgot Password?</a>
              </small>
            </div>
          </div>
          <div style="border-radius: 5px;" *ngIf="(error$ | async)" id="a-not-found" class="text-center bg-danger border-danger text-white mr-5 ml-5 mb-4 mt-3">
            Incorrect username or password
          </div>
          <div class="text-center mt-4 mt-md-3">
            <button pButton pRipple type="submit" icon="pi pi-sign-in" label="Login" class="p-button-rounded  p-button-primary p-button-sm"></button>
          </div>
        </form>

        <div class="ml-1 mr-1 ml-md-4 mr-md-4 mt-4 text-center text-md-left">
          <a class="register" (click)="navigateToRegistration()">You Don't have an account? SIGN UP</a>
        </div>
      </div>
    </div>
</div>


