import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppState} from '../../../reducers';
import {Store} from '@ngrx/store';
import {WelcomeAction} from '../../store/actions/welcome.action.types';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {delay, filter, map, take} from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  private token: string;
  private subscription: Subscription;

  constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.subscription = this.route.paramMap.pipe(
      map(
        (param: ParamMap) => {
          return this.token = param.get('token');
        }
      ),
      filter(token => token !== undefined || token !== null),
      delay(0),
      map(token => {
        return this.store.dispatch(WelcomeAction.resetPassword({token}));
      }),
      take(1),
    ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  goToLogin() {
    this.router.navigateByUrl('/login');
  }

}
