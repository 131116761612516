import {createAction} from '@ngrx/store';

/*
  Action's that showLoadingIndicator the loading data spinner
*/
export const showLoadingIndicator = createAction('[Show Loading Data Spinner] App shared');

/*
  Action's that hide the loading data spinner
*/
export const hideLoadingIndicator = createAction('[Hide Loading Data Spinner] App shared');
