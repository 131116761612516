<div class="text-center mt-3">
  <a class="side-bar-card m-auto" href="https://lp.elis.org/pm-test-elis">
    <div class="text-center">
      <i class="pi pi-home"></i>
    </div>
    <div class="text-center">
      HOME
    </div>
  </a>
</div>
<div class="text-center mt-3">
  <a class="side-bar-card m-auto" href="https://lp.elis.org/pm-test-elis/#lp-pom-block-645">
    <div class="text-center">
      <i class="pi pi-info-circle"></i>
    </div>
    <div class="text-center">
      CERTIFICATIONS
    </div>
  </a>
</div>
<div class="text-center mt-3">
  <a class="side-bar-card m-auto" href="https://lp.elis.org/pm-test-elis/#lp-pom-block-1233">
    <div class="text-center">
      <i class="pi pi-dollar"></i>
    </div>
    <div class="text-center">
      PLANS AND PRICES
    </div>
  </a>
</div>
<div class="text-center mt-3">
  <a class="side-bar-card m-auto" href="https://lp.elis.org/pm-test-elis/#lp-pom-block-376">
    <div class="text-center">
      <i class="pi pi-key"></i>
    </div>
    <div class="text-center">
      MAIN FEATURES
    </div>
  </a>
</div>
<div class="text-center mt-3">
  <a class="side-bar-card m-auto" target="_blank" href="https://lp.elis.org/pm-test-elis/faq/">
    <div class="text-center">
      <i class="pi pi-comment"></i>
    </div>
    <div class="text-center">
      FAQ
    </div>
  </a>
</div>
<div class="text-center mt-3">
  <a class="side-bar-card m-auto" target="_blank" href="https://lp.elis.org/pm-test-elis/digital-media/">
    <div class="text-center">
      <i class="pi pi-file"></i>
    </div>
    <div class="text-center">
      DIGITAL MEDIA
    </div>
  </a>
</div>
<div class="text-center mt-3">
  <a class="side-bar-card m-auto" target="_blank" href="https://f6218ff2.sibforms.com/serve/MUIEAADhrq8YDzJJVPEEhqogaT0uVUBVkVgv72Dk96fSis-1OOKHtZ6cy5Gphwd7R7u2LjHgJOuRiH25glUcMO2jy5VjjNh-L_K-hLzHj48oZITXW9cw2yK6jkIpxF9jgZQe3__lCsmH-0JndCLxE1UQPId3xb87ZPv0wujQz9OUaQPlHymONgfVCSvN2E1GnrzRJBATbvmL96Zr">
    <div class="text-center">
      <i class="pi pi-envelope"></i>
    </div>
    <div class="text-center">
      CONTACT US
    </div>
  </a>
</div>
<div class="text-center mt-3">
  <div class="side-bar-card m-auto" (click)="navigateToLogin()">
    <div class="text-center">
      <i class="pi pi-sign-in"></i>
    </div>
    <div class="text-center">
      SIGN IN
    </div>
  </div>
</div>
<div class="text-center mt-3">
  <div class="side-bar-card m-auto" (click)="navigateToSignup()">
    <div class="text-center">
      <i class="pi pi-user"></i>
    </div>
    <div class="text-center">
      SIGN UP
    </div>
  </div>
</div>

