<div class="row no-gutters mt-3">
  <div class="col-12">
    <h2 class="p-4">FAQ</h2>
  </div>
  <div class="col-12 mb-3">
    <div class="mb-3" *ngFor="let f of faq; let i = index">
      <div class="bg-blue p-4 d-flex align-items-center w-100" (click)="show(i)">
        <h5 class="text-uppercase mb-0">{{f.domanda}}</h5>
        <i class="pi ml-auto" [ngClass]="{'pi-chevron-down': currentShow !== i, 'pi-chevron-up': currentShow === i}"></i>
      </div>
      <div class="text-justify bg-light p-3 mt-3 shadow" *ngIf="currentShow === i">
        {{f.risposta}}
      </div>
    </div>
  </div>
</div>
<div class="row no-gutters my-5" id="contact">
  <div class="col-md-8 col-11 mx-auto">
    <div class="text-center mb-4">
      <h1 class="text-uppercase font-italic">
        Still need help?<br>Contact us!
      </h1>
      <strong>
        Our support team normally responds within two business day, but they are often much quicker.
      </strong>
    </div>
    <form [formGroup]="helpForm" (ngSubmit)="sendTicket()" id="helpForm">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label for="subject">Subject</mat-label>
        <input type="text" matInput id="subject" placeholder="Subject" formControlName="subject">
        <mat-hint *ngIf="subject.invalid && subject.touched" class="text-danger">Subject is required</mat-hint>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label for="name">Your Name</mat-label>
        <input type="text" matInput id="name" placeholder="Name" formControlName="name">
        <mat-hint *ngIf="name.invalid && name.touched" class="text-danger">Name is required</mat-hint>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label for="surname">Your Surname</mat-label>
        <input type="text" matInput id="surname" placeholder="Surname" formControlName="surname">
        <mat-hint *ngIf="surname.invalid && surname.touched" class="text-danger">Surname is required</mat-hint>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label for="email">Email</mat-label>
        <input type="email" matInput id="email" placeholder="Email" formControlName="email">
        <mat-hint *ngIf="email.invalid && email.touched" class="text-danger">Invalid email</mat-hint>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label for="message">Message</mat-label>
        <textarea matInput id="message" placeholder="Message" formControlName="message"></textarea>
        <mat-hint *ngIf="message.invalid && message.touched" class="text-danger">Message is required</mat-hint>
      </mat-form-field>
      <div class="text-center mt-3">
        <button [disabled]="helpForm.invalid" type="submit" class="btn btn-primary text-uppercase px-5">send</button>
      </div>
    </form>
  </div>
</div>
