import {Injectable} from '@angular/core';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {UserUrlHttpResources} from '../../userurl/user-url-http-resources';
import {StudentTest} from '../../model/student-test';
import {InfoPacchettoAbbonamento} from '../../model/info-pacchetto-abbonamento';
import {catchError, map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {DialogAction} from '../../../shared/store/actions/material.dialog.action.types';
import {DialogData} from '../../../shared/model/dialog.data';
import {AggiornaTestAbbonamentoBody} from "../../model/aggiorna-test-abbonamento-body";
import {AuthService} from "../../../auth/store/services/auth.service";
import {Abbonamento} from "../../model/abbonamento";
import {JwtDecodedObject} from "../../../auth/model/jwt.decoded.object";
import {User} from "../../../auth/model/user";
import {AuthActions} from "../../../auth/store/actions/action-types";
import {jwtDecode} from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  pacchettoAbbonamentoInfo: InfoPacchettoAbbonamento[];

  pacchettoUpdated = new Subject<InfoPacchettoAbbonamento>();

  private examInterval;
  updSub: Subscription;
  pacchettoId: number;
  private secondi = 0;
  private startExamDate: Date;
  subExpired: boolean;
  numeroLtFree: number;

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private authService: AuthService
  ) {}

  getTestXPacchettoAbbonamento(info: {
    idPacchetto: number,
    elencoLt: string,
    elencoSme: string,
    elencoMe: string
  }): Observable<StudentTest[]> {
    return this.http.post<StudentTest[]>(UserUrlHttpResources.getTestXPacchettoAbbonamento(), info);
  }

  getTestXPacchettoAbbonamentoCarrello(info: {
    elencoLt: string,
    elencoSme: string,
    elencoMe: string
  }): Observable<StudentTest[]> {
    return this.http.post<StudentTest[]>(UserUrlHttpResources.getTestXPacchettoAbbonamentoCarrello(), info);
  }

  salvaPacchettoAbbonamento(info: InfoPacchettoAbbonamento): Observable<any> {
    return this.http.post(UserUrlHttpResources.salvaPacchettoAbbonamento(), info, {observe: 'response'}).pipe(
      tap({
        next: (res) => {
          let token = res.headers.get('Authorization');
          if (token) {
            token = token.replace('Bearer ', '');
          }
          const code = res.headers.get('codeVerifica');
          this.authService.setToken(token);
          this.authService.setCodeVerifica(code);
          const decoded: JwtDecodedObject = jwtDecode(token);
          const user: User = {
            id: decoded.id,
            certificazione1: decoded.certificazione1,
            ruolo1: decoded.ruolo1,
            certificazione2: decoded.certificazione2,
            ruolo2: decoded.ruolo2,
            certificazione3: decoded.certificazione3,
            ruolo3: decoded.ruolo3,
            certificazione4: decoded.certificazione4,
            ruolo4: decoded.ruolo4,
            exp: decoded.exp,
            username: decoded.sub,
            ip: decoded.ip,
            userAgent: decoded.userAgent
          };
          this.store.dispatch(AuthActions.tokenUpdated({user}));
        }
      })
    );
  }

  getListaPacchettoAbbonamento(idUtente: string): Observable<InfoPacchettoAbbonamento[]> {
    return this.http.get<InfoPacchettoAbbonamento[]>(UserUrlHttpResources.getListaPacchettoAbbonamento(idUtente))
      .pipe(
        map((info) => {
          this.pacchettoAbbonamentoInfo = info;
          return info;
        })
      );
  }

  aggiornaTempoPacchettoAbbonamento(id: number, time: number): Observable<any> {
    return this.http.post(UserUrlHttpResources.aggiornaTempoPacchettoAbbonamento(), {
      idPacchetto: id,
      secondiConsumati: time
    });
  }


  startExam(pac: InfoPacchettoAbbonamento, pacId?: number): void {
    if(pac == null && !!pacId){
      pac = this.pacchettoAbbonamentoInfo.find(p => p.id == pacId);
    }
    this.pacchettoId = pac.id;
    this.startExamDate = new Date();
    this.examInterval = setInterval(() => {
      this.secondi += 10;
      if ((pac.secondiUtilizzoRimanente - this.secondi) <= 1) {
        this.endExam();
        this.subExpired = true;
        const dialogData: DialogData = {code: '400', message: 'Your subscription is expired'};
        this.store.dispatch(DialogAction.openDialog({dialogData}));
        this.pacchettoId = undefined;
        clearInterval(this.examInterval);
        this.router.navigateByUrl('user/dashboard', {state: {bypassGuard: true}});
        //this.router.navigateByUrl('user/subscriptions', {state: {bypassGuard: true}});
      } else if (this.secondi === 180) {
        this.startExamDate = new Date();
        this.secondi = 0;
        this.updSub = this.aggiornaTempoPacchettoAbbonamento(pac.id, 180).subscribe(
          (data: { idPacchetto: number, secondiRimanenti: number }) => {
            pac.secondiUtilizzoRimanente = data.secondiRimanenti;
          }
        );
      }
    }, 10000);
  }

  endExam(): void {
    if (this.pacchettoId) {
      clearInterval(this.examInterval);
      this.examInterval = undefined;
      if (this.updSub) {
        this.updSub.unsubscribe();
      }
      const secondi = Math.floor((new Date().getTime() - this.startExamDate.getTime()) / 1000);
      this.updSub = this.aggiornaTempoPacchettoAbbonamento(this.pacchettoId, secondi).subscribe(
        response => {
          this.updSub.unsubscribe();
          this.secondi = 0;
          this.startExamDate = null;
          this.pacchettoId = undefined;
        }
      );
    }
  }

  aggiornaTestPacchettoAbbonamento(body: AggiornaTestAbbonamentoBody, idUtente: string): Observable<boolean> {
    return this.http.post<InfoPacchettoAbbonamento>(UserUrlHttpResources.aggiornaTestPacchettoAbbonamento(), body).pipe(
      map(res => {
        const index = this.pacchettoAbbonamentoInfo.findIndex(p => p.id == res.id);
        this.pacchettoAbbonamentoInfo[index] = {...res};
        this.pacchettoUpdated.next(res);
        return true;
      }),
      catchError((err: HttpErrorResponse) => {
        this.store.dispatch(DialogAction.openDialog({
          dialogData: {
            code: err.status + '',
            message: 'Something goes wrong'
          }
        }));
        return of(false);
      })
    )
  }

  getNumeroLtFree(idCertificazione: number): Observable<Abbonamento>{
    return this.http.get<Abbonamento>(UserUrlHttpResources.getNumeroLtFree(idCertificazione)).pipe(
      tap({
        next: (res) => {
          this.numeroLtFree = res.numeroLt;
        }
      })
    );
  }
}
