import {createReducer, MetaReducer, on} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {LoadingIndicator} from '../actions/action-types';

export const loadingIndicatorFeatureKey = 'loadingIndicator';

export interface SharedComponentState {
  showLoadingIndicator: boolean;

}

export const sharedComponentState: SharedComponentState = {
  showLoadingIndicator: false,
};

export const sharedComponentReducer = createReducer(sharedComponentState,
  on(LoadingIndicator.showLoadingIndicator, (state, action) => {
    return {...state, showLoadingIndicator: true};
  }),
  on(LoadingIndicator.hideLoadingIndicator, (state, action) => {
    return {...state, showLoadingIndicator: false};
  })
);

export const metaReducers: MetaReducer<SharedComponentState>[] = !environment.production ? [] : [];
