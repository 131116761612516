export interface Certificazione {
  id: number;
  nome: string;
  descrizione: string;
  tipo: string;
  versione: number;
  attivo: boolean;
}


export function compareCertificazione(c1: Certificazione, c2: Certificazione){
  return c1.nome.localeCompare(c2.nome);
}
