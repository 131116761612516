<div class="text-center">
  <button (click)="logout()" pButton pRipple type="button" label="Logout" class="mb-2 p-button-outlined p-button-sm p-button-rounded"></button>
  <div class="side-bar-card mr-auto ml-auto mt-2" *ngIf="+(user$ | async).ruolo !== 2">
    <div class="text-center">
      <i class="pi pi-file-o"></i>
    </div>
    <div class="text-center mt-2">
      Test
    </div>
  </div>
  <div class="side-bar-card mr-auto ml-auto mt-2" (click)="navigate('classrooms')" *ngIf="+(user$ | async).ruolo === 2">
    <div class="text-center">
      <i class="pi pi-users"></i>
    </div>
    <div class="text-center mt-2">
      Classrooms
    </div>
  </div>
  <div class="side-bar-card mr-auto ml-auto mt-2" (click)="navigate('reportlist')" *ngIf="+(user$ | async).ruolo !== 3">
    <div class="text-center">
      <i class="pi pi-chart-line"></i>
    </div>
    <div class="text-center mt-2">
      Exam Report
    </div>
  </div>
</div>
