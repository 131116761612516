<div class="flexbox-wrapper">
  <div class="flex-item pt-2 pb-3 pl-4 pr-3">
    <img class="telephonelogo" src="assets/logo.png" alt="logo PMTest">
  </div>
  <div class="flex-item pt-2 pb-2 pl-3 pr-3">
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" class="btn p-2 mr-2 disable-outline">
      <i style="font-size: 1.2rem" class="pi pi-shopping-cart"></i>
      <span class="ml-2 badge badge-danger position-relative adjusted">0</span>
    </button>
    <button [style]="'border-color:white;outline:none;box-shadow:none;'" (click)="openCloseSideBar()"
            class="btn p-2 mr-2 disable-outline">
      <i style="font-size: 1.2rem" class="pi pi-bars"></i>
    </button>
  </div>
</div>
