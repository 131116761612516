<div class="text-center">
  <button (click)="logout()" pButton pRipple type="button" label="Logout" class="mb-2 p-button-outlined p-button-sm p-button-rounded"></button>
  <div class="side-bar-card mr-auto ml-auto mt-2" (click)="navigate('index')">
    <div class="text-center">
      <i class="pi pi-home"></i>
    </div>
    <div class="text-center mt-2">
      Dashboard
    </div>
  </div>
  <div class="side-bar-card mr-auto ml-auto mt-2" (click)="navigate('profile')">
    <div class="text-center">
      <i class="pi pi-user"></i>
    </div>
    <div class="text-center mt-2">
      Profile
    </div>
  </div>
  <div class="side-bar-card mr-auto ml-auto mt-2" (click)="navigate('classrooms')" *ngIf="(user$ | async) && +(user$ | async).ruolo === 2">
    <div class="text-center">
      <i class="pi pi-users"></i>
    </div>
    <div class="text-center mt-2">
      Classrooms
    </div>
  </div>
  <div class="side-bar-card mr-auto ml-auto mt-2" (click)="navigateToReportList()" *ngIf="(user$ | async) && +(user$ | async).ruolo !== 3">
    <div class="text-center">
      <i class="pi pi-chart-line"></i>
    </div>
    <div class="text-center mt-2">
      Exam Report
    </div>
  </div>
  <!--<div class="side-bar-card mr-auto ml-auto mt-2">
    <div class="text-center">
      <i class="pi pi-inbox"></i>
    </div>
    <div class="text-center mt-2">
      Subscriptions
    </div>
  </div>-->
</div>
