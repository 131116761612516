import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import {timezoneReducer} from '../shared/store/reducers/timezone.reducer';



// tslint:disable-next-line:no-empty-interface
export interface AppState {
}

export const reducers: ActionReducerMap<AppState> = {
  timezone: timezoneReducer
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
