import {createAction, props} from '@ngrx/store';
import {DialogData} from '../../../shared/model/dialog.data';
import {StudentTest} from '../../model/student-test';
import {StudentQuestion} from '../../model/student-question';
import {UserAnswer} from '../../model/user-answer';
import {TestCorrectionTo} from '../../model/test-correction-to';
import {TestResult} from '../../model/test-result';

export const openCalculator = createAction('[TestComponent] Open Calculator');


export const closeCalculator = createAction('[CalculatorComponent] Close Calculator');

export const getTest = createAction('[ExamListComponent] Get Me Or Sme From Server',
  props<{ testId: number, userId: number, examIsDoneInClass: boolean, classroomId: number }>()
);

export const getTestPremium = createAction('[Subscriptions Component] Get Me Or Sme From Server',
  props<{ testId: number, userId: number, subId: number}>()
);

export const successfullyGetTest = createAction('[StudentTestEffect] Succesfully Get Sme Or Me',
  props<{ test: StudentTest, examIsDoneClass: boolean }>()
);

export const failToGetTest = createAction('[StudentTestEffect] Fail To Get Sme Or Me',
  props<{ dialogData: DialogData, premium?: boolean }>()
);

export const endTest = createAction('[Clock Service] End Exam Time Is Out');

export const prepopulateAnswer = createAction('[TestQuestionComponent] Prepopulate Answer',
  props<{ question: StudentQuestion[] }>()
);

export const saveAnswer = createAction('[TestQuestionComponent] Update User Answer',
  props<{ userAnswer: UserAnswer }>()
);

export const nextQuestion = createAction('[TestQuestionComponent] Go To Next Question',
  props<{ userAnswer: UserAnswer, incrementNumberOfSeen: boolean, nextQuestionId: number }>()
);

export const previousQuestion = createAction('[TestQuestionComponent] Go To Previous Question',
  props<{ userAnswer: UserAnswer, incrementNumberOfSeen: boolean, previuosQuestionId: number }>()
);

export const navigateToSingleQuestion = createAction('[ExamReviewComponent] Navigate To Question',
  props<{ userAnswer: UserAnswer, currentIndexOfQuestionToShow: number }>()
);

export const reviewAll = createAction('[ExamReviewComponent] Review All Question',
  props<{ userAnswer: UserAnswer }>()
);

export const reviewMarked = createAction('[ExamReviewComponent] Review Marked Question',
  props<{ userAnswer: UserAnswer }>()
);


export const reviewIncomplete = createAction('[ExamReviewComponent] Review Incomplete Question',
  props<{ userAnswer: UserAnswer }>()
);


export const restoreTestData = createAction('[TestQuestionComponent] Clear Data In Case Of Browser Back Button');

export const signNextQuesttionAsSeen = createAction('[TestQuestionComponent] Sing Next Question As Seen',
  props<{ useerAnswerId: number }>()
);

export const sendDataToServer = createAction('[TestCorrectionComponent] Send Data To Server For Correction',
  props<{ transferObj: TestCorrectionTo, examIsDoneInClass: boolean, ruoloUtilizzato: number }>()
);

export const succesfullyGetTestResult = createAction('[StudentTestEffect] Succesfully Get Test Result',
  props<{ result: TestResult, update: boolean, fromReportList: boolean }>()
);

export const failToGetTestResult = createAction('[StudentTestEffect] Fail To Get Test Result');

export const restoreTestResultData = createAction('[TestResultComponent] On Destroy Method');

export const closedConfirmDialog = createAction('[ConfirmDialogEffect] Closed dialog')
