import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Ticket} from '../../../shared/model/ticket';
import {Subscription} from 'rxjs';
import {TicketService} from '../../../shared/store/service/ticket.service';
import {SharedService} from '../../../shared/store/service/shared.service';
import {Store} from "@ngrx/store";
import {selectProfileState} from "../../../user/store/selectors/profile.selector";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit, OnDestroy {
  faq: {
    domanda: string,
    risposta: string
  }[];
  currentShow: number;
  userSub: Subscription;

  helpForm: FormGroup;

  salvaSub: Subscription;
  scrollSub: Subscription;

  constructor(private ticketService: TicketService, private shared: SharedService, private store: Store) {
    this.helpForm = new FormGroup({
      subject: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.userSub = this.store.select(selectProfileState).subscribe(
      user => {
        if (user) {
          this.name.setValue(user.profile?.nome);
          this.surname.setValue(user.profile?.cognome);
          this.email.setValue(user.profile?.email);
        }
      }
    );
    this.scrollSub = this.shared.pageScroll.subscribe(fragment => {
      document.querySelector('#' + fragment).scrollIntoView({behavior: 'smooth'});
    });
    this.faq = [
      {
        domanda: 'What are the minimum requirements to use PMTest?',
        risposta: 'The PMTest requires at least Microsoft Edge 10, Firefox 54, Chrome 60 or Internet Explorer 10. The application is also accessible (but not optimized) from other browsers and mobile devices (smartphones, tablets and iPads).'
      },
      {
        domanda: 'Can I try the service for free?',
        risposta: 'Yes, just make a simple registration on the SIGN UP page or click on FREE TRIAL.'
      },
      {
        domanda: 'Is it possible to get a refund?',
        risposta: 'Yes, you can request a refund only if you have not activated the purchased subscription.'
      },
      {
        domanda: 'When will my subscription be active?',
        risposta: 'When will you launch the first test.'
      },
      {
        domanda: 'How and when should the payment be made?',
        risposta: 'You can pay via PayPal with a credit card.'
      },
      {
        domanda: 'When does subscription expire?',
        risposta: 'When all the available hours are used up or (if you have not used them) when the available days end.'
      },
      {
        domanda: 'What happens when the subscription ends?',
        risposta: 'Your account returns to FREE and you will no longer be able to view the questions or start the tests.'
      },
      {
        domanda: 'Can I extend the duration of my subscription?',
        risposta: 'Yes, you can extend the duration of the subscription by renewing or purchasing a new subscription through dashboard'
      }
    ];
  }

  sendTicket(): void {
    const ticket: Ticket = {
      titolo: this.subject.value.trim(),
      nome: this.name.value.trim(),
      cognome: this.surname.value.trim(),
      email: this.email.value.trim(),
      descrizioneRichiesta: this.message.value.trim()
    };

    this.salvaSub = this.ticketService.salvaTicket(ticket).subscribe(
      (dialogData) => {
        if (dialogData.code === '201') {
          this.resetForm();
        }
      }
    );
  }

  resetForm(): void {
    // unica soluzione - il metodo reset() del ReactiveForm va in conflitto con i mat-input
    const form = (document.getElementById('helpForm') as HTMLFormElement);
    form.reset();
  }

  get subject(): AbstractControl {
    return this.helpForm.get('subject');
  }

  get name(): AbstractControl {
    return this.helpForm.get('name');
  }

  get surname(): AbstractControl {
    return this.helpForm.get('surname');
  }

  get email(): AbstractControl {
    return this.helpForm.get('email');
  }

  get message(): AbstractControl {
    return this.helpForm.get('message');
  }

  show(index: number): void {
    if (this.currentShow === index) {
      this.currentShow = undefined;
    } else {
      this.currentShow = index;
    }
  }

  ngOnDestroy() {
    if (this.salvaSub) {
      this.salvaSub.unsubscribe();
    }

    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }

    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }
}
