import {createFeatureSelector, createSelector} from '@ngrx/store';
import {welcomeFeatureKey, WelcomeState} from '../reducers';

export const selectWelcomeState = createFeatureSelector<WelcomeState>(welcomeFeatureKey);


export const selectIsSideBarOpen = createSelector(selectWelcomeState, welcomeState => welcomeState.showSideBar);

export const selectCurrentSelectedCertificationNavBar = createSelector(selectWelcomeState, state => state.currentSelectedCertification);

export const selectNavbarVisibility = createSelector(selectWelcomeState, (state) => {
  return state.navbarVisible;
});
