import {createAction, props} from '@ngrx/store';
import {AdminTestFilterEntity} from '../../model/test-filter-entity';
import {Test} from '../../model/test';
import {DialogData} from '../../../shared/model/dialog.data';
import {Certification} from '../../model/certification';

export const getTest = createAction('[AdminTestResolver] Get Test From Server', props<{isFromClassroom: boolean}>());

export const getTestSuccess = createAction('[AdminTestEffect] Succesfully Get Test Two Param',
  props<{ certificazioni: Certification[], test: Test[] }>()
);

export const getTestSuccessOneParam = createAction('[AdminTestEffect] Succesfully Get Test One Param',
  props<{ test: Test[] }>()
);

export const failToGetTest = createAction('[AdminTestEffect] Fail To Get Test',
  props<{ dialogData: DialogData }>()
);

export const getTestFiltered = createAction('[TestToClassRoomComponent TestListComponent] Get Test Filtered',
  props<{ filterEntity: AdminTestFilterEntity }>()
);

export const getTestFilteredSuccess = createAction('[AdminTestEffect] Succesfully Get Filtered Test',
  props<{ test: Test[] }>()
);

export const getTestFilteredFail = createAction('[AdminTestEffect] Fail To Get Filtered Test',
  props<{ dialogData: DialogData }>()
);

export const getSingleTest = createAction('[TestListComponent TestToClassRoomComponent] Get One Test From Server',
  props<{ testId: number }>()
);

export const getSingleTestForClassroom = createAction('[TestToClassroomComponent] Get the Test for Classroom From Server',
  props<{ testId: number, classroomId: number }>()
);

export const setCorrectionModeInTest = createAction('[TestToClassroomComponent] Set Correction Mode in Test');

export const unsetCorrectionModeInTest = createAction('[AdminViewTestComponent] Unset Correction Mode in Test');

export const setStartIndexCorrectionTest = createAction('[AdminViewTestComponent] Set Start Index of Question in Correction Test mode',
  props<{ index: number }>()
);

export const unsetStartIndexCorrectionTest = createAction('[AdminViewTestComponent] Clean Start Index of Question in Correction Test mode');

export const succesfullyGetSingleTest = createAction('[AdminTestEffect] Succesfully Get One Test Data From Server',
  props<{ test: Test }>()
);

export const failToGetSingleTest = createAction('[AdminTestEffect] Fail To Get One Test Data From Server',
  props<{ dialogData: DialogData }>()
);

export const assignTestToClass = createAction('[TestToClassComponent] Assign A Test To A Class',
  props<{ test: Test, classRoomId: number }>()
);

export const removeTestToClass = createAction('[TestToClassComponent] Remove Test From  Class',
  props<{ test: Test, classRoomId: number }>()
);

export const assignTestToClassSuccess = createAction('[AdminTestEffect] Succesfully Enable Test',
  props<{ test: Test }>()
);

export const removeTestToClassSuccess = createAction('[AdminTestEffect] Succesfully Disable Test',
  props<{ test: Test }>()
);

export const failToAssignTestToClass = createAction('[AdminTestEffect] Fail To Assign Test From Class',
  props<{ dialogData: DialogData }>()
);

export const failToRemoveTestToClass = createAction('[AdminTestEffect] Fail To Remove Test From Class',
  props<{ dialogData: DialogData }>()
);

export const resetCurrentSelectedTest = createAction('[OnDestroy Of AdminViewTestComponent]');


export const markLtAsFreeOrPayed = createAction('[TestListComponent] Mark Lt As Free Or Payed',
  props<{ data: { idTest: number, free: boolean } }>()
);

export const succesfullyEditLtStatus = createAction('[AdminTestEffect] Succesfully Edit Lt Status',
  props<{ data: { idTest: number, free: boolean } }>()
);

export const failtToEditLtStatus = createAction('[AdminTestEffect] Fail To Edit Lt Status',
  props<{ dialogData: DialogData, data: { idTest: number, free: boolean } }>()
);

export const changeNavbarVisibility = createAction('[AdminCorrectTestComponent]',
  props<{ state: boolean }>());
