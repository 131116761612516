import {Injectable} from '@angular/core';
import {interval, Observable} from 'rxjs';
import {AppState} from '../../../reducers';
import {Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {StudentTestAction} from '../actions/studenttest.action.type';

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  private examTime: Date;
  private examTimeInSeconds: number;
  private examDuration: Date;
  private dataAlreadySet = false;

  private pauseTime: number = 0;

  private isExamInPause: boolean = false;

  constructor(private store: Store<AppState>) {
  }


  public setExamTime(hours: number, minutes: number, seconds: number) {
    if (!this.dataAlreadySet) {
      this.examTime = new Date(0, 0, 0, hours, minutes, seconds, 0);
      this.examDuration = new Date(0, 0, 0, hours, minutes, seconds, 0);
      this.examTimeInSeconds = (this.examTime.getHours() * 3600) + (this.examTime.getMinutes() * 60) + this.examTime.getSeconds();
    }
  }


  public restoreData() {
    this.examTimeInSeconds = undefined;
    this.examDuration = undefined;
    this.examTime = undefined;
    this.dataAlreadySet = false;
  }

  public changeDataAlreadySet() {
    this.dataAlreadySet = true;
  }


  public getValue(): Observable<string> {
    return interval(1000).pipe(
      map(value => {
        if ((value - this.pauseTime) === this.examTimeInSeconds) {
          this.pauseTime = 0;
          this.store.dispatch(StudentTestAction.endTest());
          return '';
        } else if ((value - this.pauseTime) > this.examTimeInSeconds) {
          return '';
        } else {
          return this.calcTime(value - this.pauseTime);
        }
      }),
      filter(val => val.charAt(0) !== '-')
    );
  }

  public pause(): void {
    this.isExamInPause = true;
  }

  public resume(): void {
    this.isExamInPause = false;
  }

  // distance è il valore emesso dall'observable
  private calcTime(distance: number): string {
    if(this.isExamInPause){
      distance = 0;
      this.pauseTime++;
    } else {
      distance = 1;
    }
    const distanceTime = new Date(0, 0, 0, 0, 0, distance, 0);
    const calculation = this.examDuration?.getTime() - distanceTime.getTime();
    const hours = Math.floor((calculation % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((calculation % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((calculation % (1000 * 60)) / 1000);
    this.examDuration = new Date(0, 0, 0, hours, minutes, seconds, 0);
    // tslint:disable-next-line:max-line-length
    return ((('' + hours).length === 1) ? ('0' + hours) : hours) + ':' + ((('' + minutes).length === 1) ? ('0' + minutes) : minutes) + ':' + ((('' + seconds).length === 1) ? ('0' + seconds) : seconds);
  }

  // To Get Exam Total Time Do: this.examTime -this.examDuration
  public getUserExamTime() {
    let time = ((this.examTime.getTime() - this.examDuration.getTime()) / 1000);
    if (time === 0) {
      time = this.examTime.getTime() / 1000;
    }
    return time;
  }
}
