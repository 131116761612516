import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {Router} from '@angular/router';
import {WelcomeAction} from '../../../store/actions/welcome.action.types';
import {MenuItem} from 'primeng/api';
import {SharedService} from '../../../../shared/store/service/shared.service';

@Component({
  selector: 'app-user-loggedout-pc',
  templateUrl: './user-loggedout-pc.component.html',
  styleUrls: ['./user-loggedout-pc.component.css']
})
export class UserLoggedoutPcComponent implements OnInit {

  constructor(private store: Store<AppState>, private router: Router, private shared: SharedService) {
  }

  // Menu items for login/registration
  items: MenuItem[];

  ngOnInit(): void {
    this.items = [
      {label: 'SIGN IN', routerLink: ['/login']},
      {label: 'SIGN UP', routerLink: ['/signup']},
    ];
  }

  openCloseSideBar() {
    this.store.dispatch(WelcomeAction.openCloseSideBar());
  }

  navigate(destination: string, fragment?: string) {
    this.router.navigateByUrl(destination);
    if (fragment) {
      setTimeout(() => {
        this.shared.pageScroll.next(fragment);
      }, 1000);
    }

  }

}
