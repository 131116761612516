import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../store/services/auth.service';
import {Store} from "@ngrx/store";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(public  auth: AuthService, private store: Store) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.auth.getToken();
    if (token) {
      const changedRequest = request.clone({
        headers: new HttpHeaders({Authorization: `${'Bearer ' + token}`, codeVerifica: AuthService.codeVerifica})
      });
      return next.handle(changedRequest);
    } else {
      return next.handle(request);
    }
  }
}
