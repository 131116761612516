import {createReducer, on} from '@ngrx/store';
import {TimezoneAction} from '../actions/timezone.action.type';
import {Timezone} from '../../model/timezone';

export const featureKey = 'timezone';

export interface TimezoneState {
  timezone: Timezone[];
}

export const timezoneInitialState: TimezoneState = {
  timezone: [],
};

export const timezoneReducer = createReducer(timezoneInitialState,
  on(TimezoneAction.successfullyGetTimezone, (state, action) => {
    const timezone = action.timeZone.slice().sort((t1, t2) => t1.nome.localeCompare(t2.nome));
    return {...state, timezone};
  })
);
