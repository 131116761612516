import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppState} from '../../../../reducers';
import {Store} from '@ngrx/store';
import {WelcomeAction} from '../../../store/actions/welcome.action.types';
import {SharedService} from "../../../../shared/store/service/shared.service";

@Component({
  selector: 'app-sidebar-user-loggedout-telephone',
  templateUrl: './sidebar-user-loggedout-telephone.component.html',
  styleUrls: ['./sidebar-user-loggedout-telephone.component.css']
})
export class SidebarUserLoggedoutTelephoneComponent implements OnInit {

  constructor(private router: Router, private store: Store<AppState>, private shared: SharedService) {
  }

  ngOnInit(): void {
  }


  navigateToLogin() {
    this.router.navigateByUrl('/login');
    this.store.dispatch(WelcomeAction.closeSideBar());
  }

  navigateToSignup() {
    this.router.navigateByUrl('/signup');
    this.store.dispatch(WelcomeAction.closeSideBar());
  }

  navigate(destination: string, fragment?: string){
    this.router.navigateByUrl(destination);
    if (fragment) {
      setTimeout(() => {
        this.shared.pageScroll.next(fragment);
      }, 1000);
    }
    this.store.dispatch(WelcomeAction.closeSideBar());
  }
}
