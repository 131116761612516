<app-nav-bar *ngIf="showNavBar$ | async"></app-nav-bar>
<div class="block-ui" *ngIf="loadingData | async">
  <div class="block-ui-box">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div class="container-fluid px-0">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
<footer class="d-flex align-items-center">
  <div style="width: 5vw"></div>
  <div class="mx-auto">
    <small>&copy;2022 PMTest - <a href="https://www.iubenda.com/terms-and-conditions/35007545" target="_blank">Terms and Conditions</a> - <a href="https://www.iubenda.com/privacy-policy/35007545" target="_blank">Privacy Policy</a></small>
  </div>
  <div class="p-2" style="width: 5vw">
    <img src="../assets/img/logo.png" class="w-100">
  </div>
</footer>

